import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { extension } from '../../../helpers/chrome';

const PrivateRoute = ({
  component: Component,
  senderToken,
  senderId,
  userLogout,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [requireLogin, setRequireLogin] = useState(false);

  useEffect(() => {
    let didCancel = false;
    const validateLogin = async () => {
      try {
        if (await extension.canRunExtension()) {
          const isValid = await extension.isExtensionLoginValid({
            SenderToken: senderToken,
            SenderID: senderId,
          });
          if (!didCancel) {
            if (!isValid && senderToken && senderId) {
              userLogout();
            }
            setRequireLogin(!isValid);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    validateLogin();

    return () => (didCancel = true);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        senderToken && !requireLogin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
                requireLogin,
              },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  senderToken: PropTypes.string,
  location: PropTypes.object,
};

export default PrivateRoute;
