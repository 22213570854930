import React from 'react';
import PropTypes from 'prop-types';

import emailIcon from '../../static/images/share/email.png';

const Email = ({ vanity }) => {
  const subject = "Here's something I thought you might like";
  const body = vanity;
  const handleClick = () => window.WF.sendEmail(subject, body);

  return (
    <img className="icon" src={emailIcon} alt="email" onClick={handleClick} />
  );
};

Email.propTypes = {
  vanity: PropTypes.string,
};

export default Email;
