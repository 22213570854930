import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-solid';

import ConnectWithPaypal from '../ConnectWithPaypal/ConnectWithPaypal';
import { makeSenderFromPaypal } from '../../../../helpers/api';
import { userPaypalSelector } from '../../../../selectors/user';
import { getUserPaypal } from '../../../../actions';
import './PaypalConnect.scss';
import { extension } from '../../../../helpers/chrome';

const PaypalConnect = ({ device }) => {
  const [code, setCode] = useState();
  const [canConnect, setCanConnect] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();

  const userPaypal = useSelector(userPaypalSelector);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const c = searchParams.get('code');
    if (c) {
      setCode(c);
    }
  }, []);

  useEffect(() => {
    setCanConnect(Boolean(!code));
  }, [code, device]);

  useEffect(() => {
    if (hasError) {
      setTimeout(() => setHasError(false), 3000);
    }
  }, [hasError]);

  useEffect(() => {
    if (userPaypal.recipient_type !== 'PAYPAL_ID' && code && !hasError) {
      const makeSenderFromCode = async (code) => {
        try {
          const response = await makeSenderFromPaypal(code);
          if (response.ok) {
            const data = await response.json();
            if (!data.SenderToken)
              throw new Error('Missing Sender Token in response');
            if (
              (await extension.canRunExtension()) &&
              !(await extension.isExtensionLoginValid(data))
            ) {
              const wasSenderCreationSuccessful = await extension.makeSenderFromPaypalExtension(
                data,
              );
              if (!wasSenderCreationSuccessful) {
                await extension.clear();
                const wasSenderCreationSuccessful2 = await extension.makeSenderFromPaypalExtension(
                  data,
                );
                if (!wasSenderCreationSuccessful2) {
                  throw new Error(
                    'Could not create sender after second attempt and clearing extension',
                  );
                }
              }
            }
            dispatch(getUserPaypal());
          } else {
            setHasError(true);
            setCode('');
          }
        } catch (error) {
          setHasError(true);
          setCode('');
          console.error(error);
        }
      };

      makeSenderFromCode(code);
    }
  }, [code, userPaypal, hasError]);

  return (
    <div className="paypal-connect">
      {hasError ? (
        <div className="error">Please try again later...</div>
      ) : userPaypal.recipient_type === 'PAYPAL_ID' ? (
        <div className="connected">
          <FontAwesomeIcon icon={faCheckCircle} />
          PayPal Connected
        </div>
      ) : (
        <ConnectWithPaypal canConnect={canConnect} />
      )}
    </div>
  );
};

export default PaypalConnect;
