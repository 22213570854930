import { faCoins } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { makeJumpURL } from '../../../helpers';
import ShareContainer from '../../Share/ShareContainer';
import './MerchantListItem.css';
import MerchantLogoPlaceholder from './MerchantLogoPlaceholder/MerchantLogoPlaceholder';

class MerchantListItem extends Component {
  handleMerchantClick = (e) => {
    if (e.target.classList.contains('rate')) {
      return;
    }

    const { merchant, isSearchResult } = this.props;

    if (isSearchResult) {
      window.gtag('event', 'search result click', {
        event_category: 'search',
        event_label: merchant.Name,
      });
    }

    window.WF.openUrl(makeJumpURL(merchant.ShortURL));
  };

  getLogoImage = () => {
    const { merchant } = this.props;

    for (let i = 0; i < merchant.Images.length; i++) {
      const image = merchant.Images[i];

      if (image.Kind === 'LOGO') {
        return image.URL;
      }
    }

    return false;
  };

  getDefaultRate = (activeDomainsMerchant) => {
    if (!activeDomainsMerchant) {
      return null;
    }

    return activeDomainsMerchant.parsedRate;
  };

  render() {
    const { merchant, isSearchResult, activeDomainsMerchant } = this.props;
    const logo = this.getLogoImage();
    const defaultRate = this.getDefaultRate(activeDomainsMerchant);

    return (
      <div className="merchant-list-item">
        <div className="left" onClick={this.handleMerchantClick}>
          {logo ? (
            <img className="logo" src={logo} alt="merchant logo" />
          ) : (
            <MerchantLogoPlaceholder merchant={merchant} />
          )}
          <h3>
            <span>{merchant.Name}</span>
          </h3>
        </div>
        <div className="right">
          <Link className="rate" to={`/stores/${merchant.ID}`}>
            <span className="text">
              {defaultRate ? `Up to ${defaultRate}` : 'See rates'}
            </span>
            <FontAwesomeIcon icon={faCoins} className="icon" size="lg" />
          </Link>
          <ShareContainer
            merchantName={merchant.Name}
            shortCode={merchant.ShortCode}
            isSearchResult={isSearchResult}
            shortURL={merchant.ShortURL}
          />
        </div>
      </div>
    );
  }
}

MerchantListItem.propTypes = {
  merchant: PropTypes.object.isRequired,
  isSearchResult: PropTypes.bool,
  activeDomainsMerchant: PropTypes.object,
};

export default MerchantListItem;
