import React from 'react';

import './Verizon.css';
import checkMark from './assets/images/check.svg';
import step1 from './assets/images/step1.png';
import step2 from './assets/images/step2.png';
import step3 from './assets/images/step3.png';
import step4 from './assets/images/step4.png';
import step5 from './assets/images/step5.png';
import step6 from './assets/images/step6.png';

const steps = [
  {
    number: 1,
    image: step1,
    description: 'Tap the text size icon in the menu bar',
  },
  {
    number: 2,
    image: step2,
    description: 'Tap Manage Extensions',
  },
  {
    number: 3,
    image: step3,
    description: 'Toggle Verizon to ON',
  },
  {
    number: 4,
    image: step4,
    description: 'Tap Verizon',
  },
  {
    number: 5,
    image: step5,
    description: 'Tap "Always Allow"',
  },
];

const Step = ({ number, image, description }) => (
  <div className="step">
    <div className="step-title">
      {number && <div className="step-number">{number}</div>}
      <div className="step-description">{description}</div>
    </div>
    <div className="step-image-container">
      <img src={image} className="step-image" />
    </div>
  </div>
);

const Verizon = () => {
  return (
    <main id="container">
      <div className="heading">
        <h1>
          Five easy steps to install{' '}
          <span>
            <img src={checkMark} className="check-mark" />
          </span>
        </h1>
      </div>
      <div className="steps">
        {steps.map((data, i) => (
          <Step key={i} {...data} />
        ))}
        <div className="step">
          <div className="step-title final-step">
            <div className="step-description final-step">
              Tap "Always Allow on Every Website"
            </div>
          </div>
          <div className="step-image-container final-step">
            <img src={step6} className="step-image" />
          </div>
        </div>
        <div className="step step-footer">
          You're all set to earn cash back!
        </div>
      </div>
    </main>
  );
};

export default Verizon;
