import React from 'react';

import './TermsAndPrivacy.scss';

const TermsAndPrivacy = () => {
  const handleTermsClick = () =>
    window.WF.openUrl('https://www.wildlink.me/terms');

  const handlePrivacyClick = () =>
    window.WF.openUrl('https://www.wildlink.me/privacy');

  return (
    <div className="terms-and-privacy">
      to our <span onClick={handleTermsClick}>Terms</span> and{' '}
      <span onClick={handlePrivacyClick}>Privacy Policy</span>
    </div>
  );
};

export default TermsAndPrivacy;
