import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './MerchantLogoPlaceholder.css';

const MerchantLogoPlaceholder = ({ merchant }) => {
  const { Name = ' ', ID = 0 } = merchant;

  const fallbackColorMap = [
    'seafoam',
    'green',
    'blue',
    'pink',
    'purple',
    'orange',
  ];

  const merchantLogoPlaceholderClass = classNames('merchant-logo-placeholder', {
    [fallbackColorMap[ID % 6]]: ID !== 0,
  });

  return <div className={merchantLogoPlaceholderClass}>{Name[0]}</div>;
};

MerchantLogoPlaceholder.propTypes = {
  merchant: PropTypes.shape({
    Name: PropTypes.string,
    ID: PropTypes.number,
  }),
};

export default MerchantLogoPlaceholder;
