import React from 'react';

import './Patents.scss';

const PATENTS = [
  '10,169,770',
  '10,229,427',
  '10,402,845',
  '10,540,671',
  '10,643,230',
  '10,733,622',
  '11,354,694',
];

const Patents = () => {
  const patents = PATENTS.join('; ');

  return (
    <div className="patents">
      <div className="patents-detail">
        <h2>Patents</h2>
        <div>
          This page is intended to serve as notice under 35 U.S.C. § 287(a).
        </div>
      </div>
      <div className="consumer-products">
        <div>
          <table>
            <tbody>
              <tr>
                <td>Wildlink</td>
                <td>
                  U.S. Pat. Nos. {patents}.
                  <br />
                  Other patents pending.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Patents;
