/*eslint no-unused-expressions: "off"*/
import React from 'react';
import './Modal.css';

export default function Modal(props) {
  const {
    btnText,
    firstModal,
    toggleUserModal,
    isModalShowing,
    userDismissedModal,
  } = props;
  const showHideClassName = isModalShowing
    ? 'modal-bg'
    : 'modal-bg display-none';

  const handleClick = () => {
    if (firstModal) {
      window.WF.navigateToSettings?.();
    } else {
      window.WF.showExample?.('https://www.walmart.com');
    }
    toggleUserModal(false);
  };

  const handleDismiss = () => {
    window.WF.modalDismissed?.();
    toggleUserModal(false);
    userDismissedModal();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {props.children}
        <div className="buttons">
          <div onClick={handleDismiss}>Not Now</div>
          <div onClick={handleClick}>{btnText}</div>
        </div>
      </section>
    </div>
  );
}
