import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { TITLE_MAP } from '../../constants';
import { isMobileApp } from '../../helpers';

import Navigation from '../Navigation/Navigation';
import Title from '../Title/Title';

import './Header.css';

const Header = ({ senderToken, location }) => {
  const { pathname } = location;
  const title = TITLE_MAP[pathname];

  const headerClass = classNames('header', {
    empty: !title && isMobileApp(),
    [`header-${title}`]: !!title,
    'header-login': pathname === '/login' || pathname === '/verify',
    'not-logged-in': !senderToken,
  });

  return (
    <div className={headerClass}>
      {isMobileApp() ? <Title title={title} /> : null}
      <Navigation />
    </div>
  );
};

Header.propTypes = {
  senderToken: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.object,
  }),
};

export default Header;
