// config for reamaze support and contact
window._support = { ui: {}, user: {} };
window._support['account'] = 'wildlink';
window._support['ui']['contactMode'] = 'default';
window._support['ui']['enableKb'] = 'true';
window._support['ui']['styles'] = {
  widgetColor: 'rgba(72, 173, 200, 1)',
};
window._support['custom_fields'] = {
  'Your Wildlink phone or email (if different from above)': {
    pos: 1534965748932,
    type: 'text',
    value: '',
    required: false,
    placeholder: '',
  },
};

// contact lightbox
window._support['apps'] = {
  faq: {
    header: 'Find the answers you need',
    placeholder: 'Search our FAQ',
    enabled: true,
  },
  recentConversations: {
    header: 'Recent Conversations',
    buttonText: 'New Conversation',
  },
};
