import { connect } from 'react-redux';

import {
  bannerMerchantsIsLoadingSelector,
  bannerMerchantsSelector,
  activeBannersSelector,
  activeBannerMerchantsSelector,
} from '../../selectors';

import { getBannerMerchants } from '../../actions';

import BannerCarousel from './BannerCarousel';

const mapStateToProps = (state) => ({
  bannerMerchantsIsLoading: bannerMerchantsIsLoadingSelector(state),
  bannerMerchants: bannerMerchantsSelector(state),
  activeBanners: activeBannersSelector(),
  activeBannerMerchants: activeBannerMerchantsSelector(),
});

const mapActionsToProps = {
  getBannerMerchants,
};

export default connect(mapStateToProps, mapActionsToProps)(BannerCarousel);
