import React from 'react';
import { Switch, Route } from 'react-router-dom';

import asyncComponent from '../../components/Route/AsyncComponent';

import './MainHome.css';

const AsyncHome = asyncComponent(() => import('../../pages/Home/Home'));
const AsyncAbout = asyncComponent(() => import('../../pages/About/About'));

const MainHome = () => {
  return (
    <div className="main-home">
      <Switch>
        <Route path="/" exact component={AsyncHome} />
        <Route path="/about" component={AsyncAbout} />
      </Switch>
    </div>
  );
};

export default MainHome;
