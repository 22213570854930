import React from 'react';

import PaypalConnect from './PaypalConnect/PaypalConnect';

const PaymentPreferences = ({ deviceID }) => (
  <div className="payout">
    <h3>Payout Details</h3>
    <PaypalConnect />
    <div className="deviceID">DeviceID: {deviceID}</div>
    <div className="detail">
      Earnings are paid monthly for all Confirmed earnings above $5.
    </div>
  </div>
);

export default PaymentPreferences;
