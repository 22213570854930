import { combineReducers } from 'redux';
import initialState from './initialState';
import { R } from './constants';
import { removeUserFromLocalStorage } from './helpers';

import user from './reducers/user';
import merchants from './reducers/merchants';
import earnings from './reducers/earnings';
import clicks from './reducers/clicks';
import stats from './reducers/stats';
import errors from './reducers/errors';

const appReducer = combineReducers({
  user,
  merchants,
  earnings,
  clicks,
  stats,
  errors,
});

const rootReducer = (state, action) => {
  if (action.type === R.USER_LOGOUT) {
    state = undefined;
    state = initialState();
  }

  if (action.type === R.SET_BAD_AUTH) {
    state = undefined;
    removeUserFromLocalStorage();
    state = initialState();
  }

  return appReducer(state, action);
};

export default rootReducer;
