import React from 'react';
import { Link } from 'react-router-dom';

import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>Page Not Found</h1>
      <div>
        The page you are looking for could not be found. Check the address bar
        to ensure your URL is spelled correctly. If all else fails, you can
        visit our home page at the link below.
      </div>
      <Link to="/">Visit the Home Page</Link>
    </div>
  );
};

export default NotFound;
