import React from 'react';
import PropTypes from 'prop-types';

import { makeJumpURL } from '../../helpers';

const MerchantBanner = ({ banner }) => {
  const handleBannerClick = () => {
    window.gtag('event', 'banner click', {
      event_category: 'stores',
      event_label: `${banner.Name}: ${banner.description}`,
    });
    window.WF.openUrl(makeJumpURL(banner.ShortURL));
  };

  if (typeof banner.image === 'undefined') {
    return null;
  }

  return (
    <div className="banner" onClick={handleBannerClick}>
      <img src={banner.image} alt={`${banner.Name}`} />
    </div>
  );
};

MerchantBanner.propTypes = {
  banner: PropTypes.shape({
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    ShortURL: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
  }),
};

export default MerchantBanner;
