import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { TITLE_MAP } from '../../../constants';

import storeIcon from '../../../static/images/mobile-nav/store.png';
import storeIconActive from '../../../static/images/mobile-nav/store-active.png';
import statsIcon from '../../../static/images/mobile-nav/stats.png';
import statsIconActive from '../../../static/images/mobile-nav/stats-active.png';
import profileIcon from '../../../static/images/mobile-nav/profile.png';
import profileIconActive from '../../../static/images/mobile-nav/profile-active.png';
import activityIcon from '../../../static/images/mobile-nav/activity.png';
import activityIconActive from '../../../static/images/mobile-nav/activity-active.png';

import './MobileNav.css';

class MobileNav extends Component {
  state = {
    keyboardIsShowing: false,
    originalHeight: document.documentElement.clientHeight,
    originalWidth: document.documentElement.clientWidth,
  };

  // on android, the keyboard pushes the mobile nav up
  handleResize = (e) => {
    let { originalHeight, originalWidth } = this.state;
    const currentHeight = document.documentElement.clientHeight;
    const currentWidth = document.documentElement.clientWidth;
    // handle landscape/portrait mode switch
    if (currentHeight === originalWidth && currentWidth === originalHeight) {
      originalWidth = currentWidth;
      originalHeight = currentHeight;
      this.setState({
        originalHeight,
        originalWidth,
      });
    }
    // check if the available height is smaller (keyboard is shown)
    if (currentHeight < originalHeight) {
      this.setState({ keyboardIsShowing: true });
    } else {
      this.setState({ keyboardIsShowing: false });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { keyboardIsShowing } = this.state;
    const { pathname } = this.props.location;
    const title = TITLE_MAP[pathname];
    const mobileNavClass = title ? `mobile-nav-${title}` : '';

    if (keyboardIsShowing) {
      return null;
    } else {
      return (
        <div className={`mobile-nav ${mobileNavClass}`}>
          <Link to="/stores">
            {pathname === '/stores' ? (
              <img src={storeIconActive} alt="stores" />
            ) : (
              <img src={storeIcon} alt="stores" />
            )}
          </Link>
          <Link to="/stats">
            {pathname === '/stats' ? (
              <img src={statsIconActive} alt="stats" />
            ) : (
              <img src={statsIcon} alt="stats" />
            )}
          </Link>
          <Link to="/community-activity">
            {pathname === '/community-activity' ? (
              <img src={activityIconActive} alt="community activity" />
            ) : (
              <img src={activityIcon} alt="community activity" />
            )}
          </Link>
          <Link to="/settings">
            {pathname === '/settings' ? (
              <img src={profileIconActive} alt="profile" />
            ) : (
              <img src={profileIcon} alt="profile" />
            )}
          </Link>
        </div>
      );
    }
  }
}

MobileNav.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(MobileNav);
