import './helpers/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

// import registerServiceWorker from './helpers/registerServiceWorker';
import './static/js/WF';
import './static/js/GA';
import './static/js/reamaze';

import store, { history } from './store';
import { wfUserAgent, isLocalDev } from './helpers';

import App from './App';

import 'react-tippy/dist/tippy.css';
import './index.scss';

if (!isLocalDev) {
  Sentry.init({
    dsn: 'https://9d298c63a2e4482eb76bfea1471c8ae9@sentry.io/1246632',
    release: wfUserAgent(),
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route component={App} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// registerServiceWorker();
