import { wfFetch } from './api';
import { DATA_URLS, R } from '../constants';
import { parseActiveDomains, parseCommissionRates } from '../helpers';

export const merchantCategoriesIsLoading = () => ({
  type: R.GET_ALL_CATEGORY_MERCHANTS_IS_LOADING,
});

export const setMerchantCategories = (merchantCategories) => ({
  type: R.SET_MERCHANT_CATEGORIES,
  payload: {
    merchantCategories,
  },
});

export const getMerchantCategories = () => {
  return async (dispatch) => {
    dispatch(merchantCategoriesIsLoading());
    try {
      const merchantCategories = await dispatch(
        wfFetch('GET', '/api/merchants/categories'),
      );
      dispatch(setMerchantCategories(merchantCategories));
      await dispatch(getAllCategoryMerchants());
    } catch (error) {
      dispatch(getAllCategoryMerchantsFailure(error.message));
    }
  };
};

export const setMerchantsById = (merchantsById) => ({
  type: R.SET_MERCHANTS_BY_ID,
  payload: {
    merchantsById,
  },
});

export const setActiveDomainsMerchants = (activeDomainsMerchants) => ({
  type: R.SET_ACTIVE_DOMAINS_MERCHANTS,
  payload: {
    activeDomainsMerchants,
  },
});

export const getAllCategoryMerchantsSuccess = () => ({
  type: R.GET_ALL_CATEGORY_MERCHANTS_SUCCESS,
});

export const getAllCategoryMerchantsFailure = (errorMessage) => ({
  type: R.GET_ALL_CATEGORY_MERCHANTS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const getAllCategoryMerchants = () => {
  return async (dispatch) => {
    try {
      // get active domains data from JSON
      const activeDomainsResponse = await fetch(
        `${DATA_URLS.ACTIVE_DOMAINS}?preview=1`,
      );
      const activeDomainsData = await activeDomainsResponse.json();
      dispatch(
        setActiveDomainsMerchants(parseActiveDomains(activeDomainsData)),
      );

      // get initial merchants
      const { merchants: initialMerchants } = await dispatch(
        wfFetch('GET', '/api/merchants/all-category-merchants?initial=1'),
      );

      dispatch(setMerchantsById(initialMerchants));

      const { merchants: restMerchants } = await dispatch(
        wfFetch('GET', '/api/merchants/all-category-merchants'),
      );

      dispatch(setMerchantsById(restMerchants));
      dispatch(getAllCategoryMerchantsSuccess());
    } catch (error) {
      dispatch(getAllCategoryMerchantsFailure(error.message));
    }
  };
};

export const getMerchantCommissionRates = (merchantId) => {
  return async (dispatch) => {
    try {
      const commissionRates = await dispatch(
        wfFetch('GET', `/api/merchants/${merchantId}/commission`),
      );
      const parsedCommissionRates = parseCommissionRates(commissionRates);
      if (commissionRates.status !== 'error') {
        dispatch(
          setMerchantCommissionRates({ [merchantId]: parsedCommissionRates }),
        );
      } else {
        dispatch(
          setMerchantsById({ [merchantId]: { Name: ' Store Not Found' } }),
        );
      }
    } catch (error) {}
  };
};

export const getMerchant = (merchantId) => {
  return async (dispatch) => {
    try {
      const merchant = await dispatch(
        wfFetch('GET', `/api/merchants?id=${merchantId}`),
      );
      if (merchant.status !== 'error' && merchant.length > 0) {
        dispatch(setMerchantsById({ [merchantId]: merchant[0] }));
      }
    } catch (error) {
      dispatch(
        setMerchantsById({ [merchantId]: { Name: ' Store Not Found' } }),
      );
    }
  };
};

export const setMerchantCommissionRates = (commissionRates) => ({
  type: R.SET_MERCHANT_COMMISSION_RATES,
  payload: {
    commissionRates,
  },
});

// search merchants
export const searchMerchantsIsLoading = () => ({
  type: R.SEARCH_MERCHANTS_IS_LOADING,
});

export const searchMerchants = (search) => {
  return async (dispatch) => {
    dispatch(searchMerchantsIsLoading());
    try {
      const data = await dispatch(
        wfFetch('GET', `/api/merchants/search?q=${search}`),
      );
      if (data.merchants.length > 0) {
        dispatch(searchMerchantsSuccess(data.merchants));
      } else {
        dispatch(noMerchantSearchResults());
      }
    } catch (error) {
      dispatch(searchMerchantsFailure(error.message));
    }
  };
};

export const searchMerchantsSuccess = (merchants) => ({
  type: R.SEARCH_MERCHANTS_SUCCESS,
  merchants,
});

export const searchMerchantsFailure = (error) => ({
  type: R.SEARCH_MERCHANTS_FAILURE,
  error,
});

export const noMerchantSearchResults = () => ({
  type: R.NO_MERCHANT_SEARCH_RESULTS,
});

export const clearSearchedMerchants = () => ({
  type: R.CLEAR_SEARCHED_MERCHANTS,
});

export const setMerchantSearchQuery = (searchQuery) => ({
  type: R.SET_MERCHANT_SEARCH_QUERY,
  searchQuery,
});

export const updateListMerchants = (showMerchantList) => ({
  type: R.UPDATE_LIST_MERCHANTS,
  showMerchantList,
});

export const toggleListMerchants = () => {
  return (dispatch, getState) => {
    const { listMerchants } = getState().merchants;
    dispatch(updateListMerchants(!listMerchants));
  };
};

export const getBannerMerchants = (ids) => {
  return async (dispatch) => {
    try {
      dispatch({ type: R.BANNER_MERCHANTS_IS_LOADING });
      const bannerMerchants = await dispatch(
        wfFetch('GET', `/api/merchants?id=${ids.join('&id=')}`),
      );
      if (bannerMerchants.status === 'error') {
        throw new Error(bannerMerchants);
      } else {
        dispatch({ type: R.SET_BANNER_MERCHANTS, bannerMerchants });
      }
    } catch (error) {
      dispatch({ type: R.SET_BANNER_MERCHANTS, bannerMerchants: [] });
    }
  };
};
