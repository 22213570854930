import { createSelector } from 'reselect';

import { banners } from '../components/BannerCarousel/BannerCarousel';

export const merchantCategoriesSelector = (state) =>
  state.merchants.merchantCategories;

export const merchantByIdSelector = (state, id) =>
  state.merchants.merchantsById[id];

export const allCategoryMerchantsLoadingSelector = (state) =>
  state.merchants.allCategoryMerchantsLoading;

export const allCategoryMerchantsSuccessSelector = (state) =>
  state.merchants.allCategoryMerchantsSuccess;

export const allCategoryMerchantsErrorSelector = (state) =>
  state.merchants.allCategoryMerchantsError;

export const commissionRatesSelector = (state, id) =>
  state.merchants.commissionRatesByMerchantId[id];

export const activeDomainsMerchantSelector = (state, id) =>
  state.merchants.activeDomainsMerchants[id];

export const searchMerchantsIsLoadingSelector = (state) =>
  state.merchants.searchMerchantsIsLoading;

export const searchMerchantsErrorSelector = (state) =>
  state.merchants.searchMerchantsError;

export const searchedMerchantsSelector = (state) =>
  state.merchants.searchedMerchants;

export const searchMerchantsQuerySelector = (state) =>
  state.merchants.searchMerchantsQuery;

export const noSearchMerchantsResultsSelector = (state) =>
  state.merchants.noSearchMerchantsResults;

export const bannerMerchantsIsLoadingSelector = (state) =>
  state.merchants.bannerMerchantsIsLoading;

export const bannerMerchantsSelector = (state) => {
  const { bannerMerchants } = state.merchants;
  const bannerMerchantsById = {};

  // index banner merchants by id
  for (let i = 0; i < bannerMerchants.length; i++) {
    const current = bannerMerchants[i];
    bannerMerchantsById[current.ID] = current;
  }

  return bannerMerchantsById;
};

export const activeBannersSelector = () =>
  banners.filter((merchant) => Date.now() < merchant.ends);

export const activeBannerMerchantsSelector = createSelector(
  activeBannersSelector,
  (activeBanners) =>
    activeBanners.filter((banner) => typeof banner.merchantId !== 'undefined'),
);
