import { connect } from 'react-redux';
import { activeDomainsMerchantSelector } from '../../../selectors';
import MerchantListItem from './MerchantListItem';

const mapStateToProps = (state, props) => ({
  activeDomainsMerchant: activeDomainsMerchantSelector(
    state,
    props.merchant.ID,
  ),
});

export default connect(mapStateToProps)(MerchantListItem);
