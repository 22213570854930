import { R, STRINGS as S } from '../constants';

export const defaultStats = {
  isLoading: true,
  activityFilter: S.ALL_ACTIVITY,
  error: null,
};

const reducer = (state = defaultStats, action) => {
  switch (action.type) {
    case R.STATS_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case R.GET_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case R.GET_STATS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case R.SET_ACTIVITY_FILTER:
      return {
        ...state,
        activityFilter: action.filter,
      };
    default:
      return state;
  }
};

export default reducer;
