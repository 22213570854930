import { connect } from 'react-redux';

import {
  searchMerchantsIsLoadingSelector,
  searchedMerchantsSelector,
  searchMerchantsQuerySelector,
} from '../../../selectors/merchants';

import {
  searchMerchants,
  clearSearchedMerchants,
  setMerchantSearchQuery,
  toggleListMerchants,
} from '../../../actions';

import SearchMerchants from './SearchMerchants';

const mapStateToProps = (state) => ({
  isLoading: searchMerchantsIsLoadingSelector(state),
  searchedMerchants: searchedMerchantsSelector(state),
  searchQuery: searchMerchantsQuerySelector(state),
  listMerchants: state.merchants.listMerchants,
});

const mapActionToProps = {
  searchMerchants,
  clearSearchedMerchants,
  setMerchantSearchQuery,
  toggleListMerchants,
};

export default connect(mapStateToProps, mapActionToProps)(SearchMerchants);
