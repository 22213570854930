import { connect } from 'react-redux';
import { userLogout } from '../../../actions';
import { senderIdSelector, senderTokenSelector } from '../../../selectors';

import PrivateRoute from './PrivateRoute';

const mapStateToProps = (state) => ({
  senderToken: senderTokenSelector(state),
  senderId: senderIdSelector(state),
});

const mapActionsToProps = {
  userLogout,
};

export default connect(mapStateToProps, mapActionsToProps)(PrivateRoute);
