import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Joyride from 'react-joyride';

class StoresTutorial extends Component {
  preventDefault = (e) => {
    e.preventDefault();
  };

  handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      window.localStorage.setItem('storesTutorial', '1');
      this.removePreventScrolling();
    }
  };

  preventScrolling() {
    window.addEventListener('scroll', this.preventDefault, { passive: false });
    window.addEventListener('touchmove', this.preventDefault, {
      passive: false,
    });
    window.addEventListener('wheel', this.preventDefault, { passive: false });
  }

  removePreventScrolling() {
    window.removeEventListener('scroll', this.preventDefault);
    window.removeEventListener('touchmove', this.preventDefault);
    window.removeEventListener('wheel', this.preventDefault);
  }

  componentDidMount() {
    this.preventScrolling();
  }

  componentWillUnmount() {
    this.removePreventScrolling();
  }

  render() {
    const { canStart } = this.props;

    return (
      <div className="stores-tutorial">
        <Joyride
          run
          callback={this.handleJoyrideCallback}
          locale={{ last: 'Finish' }}
          styles={{
            options: {
              primaryColor: '#cc00cc',
            },
            tooltipContent: {
              padding: '15px 0',
            },
            overlay: {
              cursor: 'default',
            },
          }}
          continuous
          showProgress
          showSkipButton
          disableOverlayClose
          spotlightClicks
          disableScrollParentFix={true}
          steps={[
            {
              title: 'Welcome to Wildlink!',
              content: (
                <div>
                  This is the Stores page. Browse, discover, share, and shop
                  thousands of stores we work with. Click <b>Next</b> to find
                  out more!
                </div>
              ),
              target: 'body',
              placement: 'center',
              disableBeacon: true,
              styles: !canStart
                ? {
                    buttonNext: {
                      pointerEvents: 'none',
                      cursor: 'default',
                      textDecoration: 'none',
                      backgroundColor: '#cacaca',
                    },
                  }
                : {},
            },
            {
              title: 'Share',
              content: (
                <div>
                  Click <b>Share</b> to post a wild.link for a store to social
                  media or copy the wild.link to your clipboard and paste it
                  anywhere.
                </div>
              ),
              target: '.share-options',
              placement: 'top-end',
              disableBeacon: true,
            },
            {
              title: 'Stores',
              content: (
                <div>
                  Click any store to activate Wildlink for yourself. Any
                  eligible thing you buy will earn you cash back!
                </div>
              ),
              target: '.merchant-card',
              disableBeacon: true,
            },
            {
              title: 'Search',
              content: (
                <div>
                  If you don't find what you're looking for, just search.
                  Wildlink is available for over 20,000 stores.
                </div>
              ),
              target: '.search-merchants',
              disableBeacon: true,
              spotlightClicks: false,
            },
            {
              title: 'Commission Rates',
              content: (
                <div>
                  Stores have different commission rates. We show the best
                  possible rate here, but know that some exclusions and rules
                  apply.
                </div>
              ),
              target: '.rate',
              disableBeacon: true,
            },
          ]}
        />
      </div>
    );
  }
}

StoresTutorial.propTypes = {
  canStart: PropTypes.bool.isRequired,
};

export default StoresTutorial;
