import React from 'react';
import PropTypes from 'prop-types';

import './Notification.css';

const Notification = ({ type, children }) => {
  return <div className={`notification ${type || ''}`}>{children}</div>;
};

Notification.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};

export default Notification;
