import React from 'react';

import placeholder from '../../../static/images/stores/featured-placeholder.jpg';

const MerchantCardPlaceholder = () => {
  return (
    <div className="merchant-card">
      <img
        className="merchant-image"
        src={placeholder}
        alt="merchant placeholder"
      />
      <div className="detail">
        <div className="right"></div>
        <div className="left">
          <div className="share-options">
            <div style={{ opacity: 0 }}>Share</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantCardPlaceholder;
