export const deviceTokenSelector = (state) => state.user.deviceToken || '';
export const deviceIDSelector = (state) => state.user.deviceID || '';
export const deviceKeySelector = (state) => state.user.deviceKey || '';
export const uuidSelector = (state) => state.user.uuid || '';

export const senderTokenSelector = (state) => state.user.senderToken || '';
export const senderIdSelector = (state) => Number(state.user.senderId || 0);

export const userIsLoadingSelector = (state) => state.user.isLoading;

export const userErrorSelector = (state) => state.user.error;

export const phoneNumberSelector = (state) => state.user.phoneNumber;

export const paymentOptinSelector = (state) => state.user.detail.payment_optin;

export const userPaypalSelector = (state) => state.user.paypal;

export const minPayoutSelector = (state) =>
  state.user.detail.minimum_payment_amount;

export const paymentTypeSelector = (state) => state.user.detail.payment_type;

export const loginHasShownSelector = (state) => state.user.loginHasShown;

export const userDetailSelector = (state) => state.user.detail;

export const userSeeNewFeatureModalSelector = (state) =>
  state.user.shouldUserSeeNewFeatureModal;
