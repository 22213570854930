import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { isMobileApp, isMainHome } from '../../helpers';

import MobileNav from './MobileNav/MobileNav';

import blackLogo from '../../static/images/logo-black.png';
import whiteLogo from '../../static/images/logo-white.png';
import './Navigation.css';

const Navigation = () => {
  if (isMobileApp()) {
    const isSupport = window.location.pathname === '/support';
    if (isSupport) {
      return null;
    }
    return <MobileNav />;
  } else {
    return (
      <div className="navigation">
        <div className="logo">
          <Link to="/">
            <img src={isMainHome() ? whiteLogo : blackLogo} alt="logo" />
            <span>Wildlink</span>
          </Link>
        </div>
        <div className="nav">
          <NavLink to="/about" activeClassName="active">
            About
          </NavLink>
          <NavLink to="/stores" activeClassName="active">
            Stores
          </NavLink>
          <NavLink to="/stats" activeClassName="active">
            Stats
          </NavLink>
          <NavLink to="/settings" activeClassName="active">
            Settings
          </NavLink>
        </div>
      </div>
    );
  }
};

export default Navigation;
