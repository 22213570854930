import { R } from '../constants';

const defaultCloudProfile = {
  CommissionStatsSummary: {
    PendingAmount: { Amount: '0', Currency: 'USD' },
    ReadyAmount: { Amount: '0', Currency: 'USD' },
    PaidAmount: { Amount: '0', Currency: 'USD' },
  },
  CommissionStatsDetail: [],
  Notifications: null,
  Metadata: {
    PaypalConnected: false,
    Created: '',
  },
};

export const defaultEarnings = {
  earningsSummary: {},
  earningsDetail: [],
  cloudProfile: defaultCloudProfile,
};

const reducer = (state = defaultEarnings, action) => {
  switch (action.type) {
    case R.SET_EARNINGS_SUMMARY:
      return {
        ...state,
        earningsSummary: action.earningsSummary,
      };
    case R.SET_EARNINGS_DETAIL:
      return {
        ...state,
        earningsDetail: action.earningsDetail,
      };
    case R.SET_CLOUD_PROFILE:
      return {
        ...state,
        cloudProfile: action.cloudProfile,
      };
    default:
      return state;
  }
};

export default reducer;
