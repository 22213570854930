import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import throttle from 'lodash/throttle';

import { getUuid, isMobileApp } from '../../helpers';

import asyncComponent from '../../components/Route/AsyncComponent';
import PrivateRouteContainer from '../../components/Route/PrivateRoute/PrivateRouteContainer';
import RequestLoginRouteContainer from '../../components/Route/RequestLoginRoute/RequestLoginRouteContainer';

import NotFound from '../../components/NotFound/NotFound';
import Loading from '../../components/Loading/Loading';
import NetworkError from '../../components/Error/NetworkError/NetworkError';

import StoresContainer from '../../pages/Stores/StoresContainer';
import SettingsContainer from '../../pages/Settings/SettingsContainer';
import VerifyContainer from '../../pages/Login/Verify/VerifyContainer';
import ValidateEmailContainer from '../../pages/Login/ValidateEmail/ValidateEmailContainer';
import Support from '../../pages/Support/Support';
import ExtensionWelcomeContainer from '../../pages/ExtensionWelcome/ExtensionWelcomeContainer';
import Patents from '../../pages/Patents/Patents';
import StoreDetailContainer from '../../pages/Stores/StoreDetail/StoreDetailContainer';

import './Main.css';

const AsyncLoginContainer = asyncComponent(() =>
  import('../../pages/Login/LoginContainer'),
);
const AsyncStatsContainer = asyncComponent(() =>
  import('../../pages/Stats/StatsContainer'),
);
const AsyncCommunityActivity = asyncComponent(() =>
  import('../../components/CommunityActivity/CommunityActivity'),
);

class Main extends Component {
  state = {
    error: null,
  };

  getDeviceToken = async () => {
    try {
      await this.props.getDeviceToken();
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  throttledGetDeviceToken = throttle(this.getDeviceToken, 3000, {
    trailing: false,
  });

  networkErrorRetry = () => {
    const { deviceToken } = this.props;
    if (!deviceToken) {
      this.getDeviceToken();
    }
    this.setState({ error: null });
  };

  componentDidMount() {
    const {
      deviceToken,
      deviceID,
      deviceKey,
      uuid,
      setUserSeeNewFeatureModal,
    } = this.props;
    (async () => {
      setUserSeeNewFeatureModal();
      const savedUUid = await getUuid();
      if (!deviceToken || !deviceID || savedUUid !== uuid) {
        this.throttledGetDeviceToken();
      }
      if (deviceKey && deviceKey !== uuid) {
        localStorage.setItem('uuid', deviceKey);
      }
    })();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.badAuth || !nextProps.deviceToken) {
      this.throttledGetDeviceToken();
    }
    return true;
  }

  render() {
    const { error } = this.state;
    const { deviceToken } = this.props;
    const mainClass = classNames('main', {
      mobile: isMobileApp(),
    });

    if (!deviceToken) {
      return (
        <div className={mainClass}>
          {error ? (
            <NetworkError onRetry={this.networkErrorRetry} error={error} />
          ) : (
            <Loading />
          )}
        </div>
      );
    }

    return (
      <div className={mainClass}>
        <Switch>
          <RequestLoginRouteContainer
            exact
            strict
            path="/stores"
            component={StoresContainer}
          />
          <Route
            exact
            strict
            path="/stores/:merchantId"
            component={StoreDetailContainer}
          />
          <RequestLoginRouteContainer
            exact
            strict
            path="/stats"
            component={AsyncStatsContainer}
          />
          <PrivateRouteContainer
            exact
            strict
            path="/settings"
            component={SettingsContainer}
          />
          <Route exact strict path="/login" component={AsyncLoginContainer} />
          <Route exact strict path="/verify" component={VerifyContainer} />
          <Route
            exact
            strict
            path="/validate/:validationToken"
            component={ValidateEmailContainer}
          />
          <Route path="/support" component={Support} />
          <Route path="/extension" component={ExtensionWelcomeContainer} />
          <Route path="/patents" component={Patents} />

          {/* route only for mobile apps */}
          {isMobileApp() && (
            <Route
              path="/community-activity"
              component={AsyncCommunityActivity}
            />
          )}

          {/* legacy routes for website pre redesign */}
          <Route path="/earnings" render={() => <Redirect to="/stats" />} />
          <Route
            path="/click-history"
            render={() => <Redirect to="/stats" />}
          />

          {/* legacy routes for desktop app */}
          <Route path="/user/stores" render={() => <Redirect to="/stores" />} />
          <Route
            path="/user/earnings"
            render={() => <Redirect to="/stats" />}
          />
          <Route
            path="/user/click-history"
            render={() => <Redirect to="/stats" />}
          />
          <Route
            path="/user/settings"
            render={() => <Redirect to="/settings" />}
          />
          <Route
            path="/user/payment-preferences"
            render={() => <Redirect to="/settings" />}
          />
          <Route
            path="/user/change-email"
            render={() => <Redirect to="/settings" />}
          />

          {/* catch all not found */}
          <Route path="/not-found" component={NotFound} />
          <Route render={() => <Redirect to="/not-found" />} />
        </Switch>
      </div>
    );
  }
}

Main.propTypes = {
  deviceToken: PropTypes.string,
  getDeviceToken: PropTypes.func.isRequired,
  badAuth: PropTypes.bool,
};

export default Main;
