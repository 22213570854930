import { R } from '../constants';
import { wfFetch } from './api';
import { getUuid, removeUserFromLocalStorage } from '../helpers';
import { extension } from '../helpers/chrome';
import { isMobileApp } from '../helpers';

export const getDeviceToken = () => {
  return async (dispatch, getState) => {
    try {
      const uuid = await getUuid();
      const os = window.WF.getEnvironment();
      const savedDeviceKey = localStorage.getItem('deviceKey') || '';
      const data = await dispatch(
        wfFetch('POST', '/api/device', { uuid, os, deviceKey: savedDeviceKey }),
      );
      const { deviceToken, deviceID, deviceKey } = data;
      localStorage.setItem('deviceToken', deviceToken || '');
      localStorage.setItem('deviceID', deviceID || '');
      localStorage.setItem('deviceKey', deviceKey || '');
      localStorage.setItem('uuid', deviceKey || '');
      dispatch(setDeviceToken(deviceToken));
      dispatch(setDeviceID(deviceID));
      dispatch(setUuid(deviceKey));
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const setUserSeeNewFeatureModal = () => ({
  type: R.SET_USER_SEE_NEW_FEATURE_MODAL,
  shouldUserSeeNewFeatureModal: isMobileApp() && !!window.WF.showModal?.(),
});

export const forceUserSeeNewFeatureModal = (shouldSee) => ({
  type: R.SET_USER_SEE_NEW_FEATURE_MODAL,
  shouldUserSeeNewFeatureModal: shouldSee,
});

export const setUuid = (uuid) => ({ type: R.SET_UUID, uuid });

export const setDeviceToken = (deviceToken) => ({
  type: R.SET_DEVICE_TOKEN,
  deviceToken,
});
export const setDeviceID = (deviceID) => ({
  type: R.SET_DEVICE_ID,
  deviceID,
});

export const setPhone = (phone) => ({ type: R.SET_PHONE, phone });

export const setSenderToken = (senderToken, senderId) => ({
  type: R.SET_SENDER_TOKEN,
  senderToken,
  senderId,
});

export const setUserDetail = (userDetail) => ({
  type: R.SET_USER_DETAIL,
  userDetail,
});

export const userLogout = () => {
  removeUserFromLocalStorage();
  extension.clear();
  return { type: R.USER_LOGOUT };
};

export const loginHasShown = () => ({ type: R.LOGIN_HAS_SHOWN });

export const setUserPaypal = (userPaypal) => ({
  type: R.SET_USER_PAYPAL,
  userPaypal,
});

export const userIsLoading = () => ({ type: R.USER_IS_LOADING });

export const getUserDetail = () => {
  return async (dispatch) => {
    try {
      const userDetail = await dispatch(wfFetch('GET', '/api/user'));
      if (userDetail.id) {
        dispatch(setUserDetail(userDetail));
      } else {
        dispatch(setUserDetail(null));
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const doesUserHavePaypal = () => {
  return async (dispatch) => {
    try {
      const userPaypal = await dispatch(wfFetch('GET', '/api/paypal'));
      return !!userPaypal?.receiver;
    } catch (err) {
      console.error(err);
      return false;
    }
  };
};

export const getUserPaypal = () => {
  return async (dispatch) => {
    try {
      const userPaypal = await dispatch(wfFetch('GET', '/api/paypal'));
      if (userPaypal.receiver) {
        dispatch(setUserPaypal(userPaypal));
      } else {
        // no paypal record for sender
        dispatch(setUserPaypal({ receiver: '' }));
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getUser = () => {
  return async (dispatch) => {
    dispatch(userIsLoading());
    return Promise.all([dispatch(getUserPaypal()), dispatch(getUserDetail())])
      .then(() => {
        dispatch(getUserSuccess());
      })
      .catch((error) => {
        dispatch(getUserFailure(error.message));
      });
  };
};

export const getUserSuccess = () => ({ type: R.GET_USER_SUCCESS });

export const getUserFailure = (error) => ({ type: R.GET_USER_FAILURE, error });

export const sendCode = (phone, captchaKey) => {
  return (dispatch) => {
    return dispatch(wfFetch('POST', '/api/send-code', { phone, captchaKey }));
  };
};

export const verifyCode = (phone, code, extensionDeviceToken = '') => {
  return (dispatch) => {
    return dispatch(
      wfFetch('POST', '/api/validate-code', {
        phone,
        code,
        extensionDeviceToken,
      }),
    );
  };
};

export const updatePaypal = (type, value) => {
  return (dispatch) => {
    return dispatch(wfFetch('POST', '/api/paypal', { type, value }));
  };
};

export const deletePaypal = () => {
  return (dispatch) => {
    return dispatch(wfFetch('DELETE', '/api/paypal'));
  };
};

export const verifyEmail = (email_token) => {
  return (dispatch) => {
    return dispatch(wfFetch('POST', '/api/verify-email', { email_token }));
  };
};

export const updateUserDetail = (userUpdate) => {
  return (dispatch) => {
    return dispatch(wfFetch('PATCH', '/api/user', userUpdate));
  };
};

export const optinPayment = () => {
  return (dispatch) => {
    return dispatch(updateUserDetail({ payment_optin: true }));
  };
};

export const sendVerificationEmail = () => {
  return (dispatch) => {
    return dispatch(wfFetch('POST', '/api/send-email'));
  };
};

export const setPaymentType = (paymentType) => {
  return (dispatch) => {
    return dispatch(updateUserDetail({ payment_type: paymentType }));
  };
};

export const addDeviceAcquisition = (queryString) => {
  return (dispatch) => {
    return dispatch(
      wfFetch('POST', '/api/device/acquisition', { queryString }),
    );
  };
};

export const freeUpPaymentEmail = () => {
  return (dispatch) => {
    return dispatch(wfFetch('POST', '/api/payment/freeup'));
  };
};
