import { connect } from 'react-redux';

import {
  merchantByIdSelector,
  allCategoryMerchantsSuccessSelector,
  activeDomainsMerchantSelector,
} from '../../../selectors';

import MerchantCard from './MerchantCard';

const mapStateToProps = (state, ownProps) => ({
  merchant: merchantByIdSelector(state, ownProps.merchantId),
  loadingComplete: allCategoryMerchantsSuccessSelector(state),
  activeDomainsMerchants: activeDomainsMerchantSelector(
    state,
    ownProps.merchantId,
  ),
});

export default connect(mapStateToProps, null)(MerchantCard);
