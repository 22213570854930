import React from 'react';
import PropTypes from 'prop-types';

import { Carousel } from 'react-responsive-carousel';

import MerchantBanner from './MerchantBanner';
import SearchBanner from './SearchBanner';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './BannerCarousel.css';

// there are 2 types of banners: merchant and search
export const banners = [
  // {
  //   merchantId: 5478747,
  //   image: hotelsDotCom,
  //   ends: 1546329600000,
  //   description: 'black friday/cyber monday',
  // },
  {
    image: 'https://storage.googleapis.com/wildlink/web/banner/holiday-ad.jpg',
    ends: 1546329600000,
    description: 'holiday gift card',
    search: '?search=holidaygiftcardpromo',
  },
];

class BannerCarousel extends React.Component {
  async componentDidMount() {
    const {
      bannerMerchants,
      getBannerMerchants,
      bannerMerchantsIsLoading,
      activeBannerMerchants,
    } = this.props;
    if (
      Object.keys(bannerMerchants).length < activeBannerMerchants.length &&
      !bannerMerchantsIsLoading
    ) {
      const merchantIds = [];
      activeBannerMerchants.forEach((merchant) =>
        merchantIds.push(merchant.merchantId),
      );
      await getBannerMerchants(merchantIds);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { bannerMerchants } = this.props;
    // update only if there are new banner merchants from the API
    if (
      Object.keys(bannerMerchants).length <
      Object.keys(nextProps.bannerMerchants).length
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      bannerMerchants,
      activeBanners,
      activeBannerMerchants,
    } = this.props;

    // make sure banner merchants fetched from API are ready
    if (
      Object.keys(bannerMerchants).length < activeBannerMerchants.length ||
      !activeBanners.length
    ) {
      return null;
    }

    // add metadata to merchant banners
    const banners = activeBanners.map((banner) => {
      if (banner.merchantId) {
        const merchantBanner = {
          ...banner,
          ...bannerMerchants[banner.merchantId],
        };
        return merchantBanner;
      }
      return banner;
    });

    return (
      <div className="banner-carousel">
        <Carousel
          infiniteLoop
          autoPlay
          interval={5000}
          showThumbs={false}
          showStatus={false}
          showIndicators={banners.length > 1}
        >
          {banners.map((banner) => {
            if (banner.merchantId) {
              return (
                <MerchantBanner key={banner.description} banner={banner} />
              );
            }

            if (banner.search) {
              return <SearchBanner key={banner.description} banner={banner} />;
            }

            return null;
          })}
        </Carousel>
        }
      </div>
    );
  }
}

BannerCarousel.propTypes = {
  bannerMerchantsIsLoading: PropTypes.bool.isRequired,
  bannerMerchants: PropTypes.object.isRequired,
  getBannerMerchants: PropTypes.func.isRequired,
  activeBanners: PropTypes.array.isRequired,
  activeBannerMerchants: PropTypes.array.isRequired,
};

export default BannerCarousel;
