const REDUX_VARIABLES = {
  // error
  ERROR: 'ERROR',
  SKIP_UNDEFINED_DATA: 'SKIP_UNDEFINED_DATA',
  // root reducer
  SET_BAD_AUTH: 'SET_BAD_AUTH',
  USER_LOGOUT: 'USER_LOGOUT',
  // merchants/stores
  SET_MERCHANT_CATEGORIES: 'SET_MERCHANT_CATEGORIES',
  GET_ALL_CATEGORY_MERCHANTS_SUCCESS: 'GET_ALL_CATEGORY_MERCHANTS_SUCCESS',
  GET_ALL_CATEGORY_MERCHANTS_FAILURE: 'GET_ALL_CATEGORY_MERCHANTS_FAILURE',
  SET_MERCHANTS_BY_ID: 'SET_MERCHANTS_BY_ID',
  GET_ALL_CATEGORY_MERCHANTS_IS_LOADING:
    'GET_ALL_CATEGORY_MERCHANTS_IS_LOADING',
  SET_MERCHANT_COMMISSION_RATES: 'SET_MERCHANT_COMMISSION_RATES',
  SEARCH_MERCHANTS_IS_LOADING: 'SEARCH_MERCHANTS_IS_LOADING',
  SEARCH_MERCHANTS_SUCCESS: 'SEARCH_MERCHANTS_SUCCESS',
  SEARCH_MERCHANTS_FAILURE: 'SEARCH_MERCHANTS_FAILURE',
  NO_MERCHANT_SEARCH_RESULTS: 'NO_MERCHANT_SEARCH_RESULTS',
  CLEAR_SEARCHED_MERCHANTS: 'CLEAR_SEARCHED_MERCHANTS',
  SET_MERCHANT_SEARCH_QUERY: 'SET_MERCHANT_SEARCH_QUERY',
  UPDATE_LIST_MERCHANTS: 'UPDATE_LIST_MERCHANTS',
  BANNER_MERCHANTS_IS_LOADING: 'BANNER_MERCHANTS_IS_LOADING',
  SET_BANNER_MERCHANTS: 'SET_BANNER_MERCHANTS',
  // stats
  SET_ACTIVITY_FILTER: 'SET_ACTIVITY_FILTER',
  STATS_IS_LOADING: 'STATS_IS_LOADING',
  GET_STATS_SUCCESS: 'GET_STATS_SUCCESS',
  GET_STATS_FAILURE: 'GET_STATS_FAILURE',
  // earnings/commissions
  SET_EARNINGS_DETAIL: 'SET_EARNINGS_DETAIL',
  SET_EARNINGS_SUMMARY: 'SET_EARNINGS_SUMMARY',
  SET_ACTIVE_DOMAINS_MERCHANTS: 'SET_ACTIVE_DOMAINS_MERCHANTS',
  // click stats/history
  SET_CLICK_HISTORY: 'SET_CLICK_HISTORY',
  SET_SHORT_URL_LIST: 'SET_SHORT_URL_LIST',
  SET_SHORT_URL_SUMMARY: 'SET_SHORT_URL_SUMMARY',
  // user info
  SET_UUID: 'SET_UUID',
  SET_DEVICE_TOKEN: 'SET_DEVICE_TOKEN',
  SET_DEVICE_ID: 'SET_DEVICE_ID',
  SET_PHONE: 'SET_PHONE',
  SET_SENDER_TOKEN: 'SET_SENDER_TOKEN',
  SET_USER_DETAIL: 'SET_USER_DETAIL',
  LOGIN_HAS_SHOWN: 'LOGIN_HAS_SHOWN',
  SET_USER_PAYPAL: 'SET_USER_PAYPAL',
  USER_IS_LOADING: 'USER_IS_LOADING',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  SET_USER_SEE_NEW_FEATURE_MODAL: 'SET_USER_SEE_NEW_FEATURE_MODAL',
  // api
  WF_FETCH: 'WF_FETCH',
  // cloud profile
  SET_CLOUD_PROFILE: 'SET_CLOUD_PROFILE',
};

export default REDUX_VARIABLES;
