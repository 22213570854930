import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import LazyLoad from 'react-lazyload';

import { makeJumpURL } from '../../../helpers';

import MerchantCardPlaceholder from './MerchantCardPlaceholder';
import ShareContainer from '../../Share/ShareContainer';

import placeholder from '../../../static/images/stores/featured-placeholder.jpg';
import fallbackA from '../../../static/images/stores/featured-fallback/3c9.jpg';
import fallbackB from '../../../static/images/stores/featured-fallback/7d7.jpg';
import fallbackC from '../../../static/images/stores/featured-fallback/39f.jpg';
import fallbackD from '../../../static/images/stores/featured-fallback/c0c.jpg';
import fallbackE from '../../../static/images/stores/featured-fallback/c3f.jpg';
import fallbackF from '../../../static/images/stores/featured-fallback/f90.jpg';

import './MerchantCard.css';

class MerchantCard extends Component {
  imageRef = React.createRef();

  fallbackImageMap = [
    fallbackA,
    fallbackB,
    fallbackC,
    fallbackD,
    fallbackE,
    fallbackF,
  ];

  handleMerchantClick = () => {
    const { merchant } = this.props;
    window.WF.openUrl(makeJumpURL(merchant.ShortURL));
  };

  getFeaturedImage = () => {
    const { merchant } = this.props;
    if (merchant.Images) {
      for (let i = 0; i < merchant.Images.length; i++) {
        const image = merchant.Images[i];
        if (image.Kind === 'FEATURED') {
          return image.URL;
        }
      }
    }
    return this.fallbackImageMap[merchant.ID % 6];
  };

  getDefaultRate = () => {
    const { activeDomainsMerchants } = this.props;

    if (activeDomainsMerchants) {
      return activeDomainsMerchants.parsedRate;
    }
  };

  handleImageError = () =>
    (this.imageRef.current.src = this.fallbackImageMap[
      this.props.merchant.ID % 6
    ]);

  render() {
    const { merchant, loadingComplete, activeDomainsMerchants } = this.props;

    if (!merchant) {
      // all loaded but no merchant at id? means merchant is disabled
      if (loadingComplete) {
        return null;
      }
      return <MerchantCardPlaceholder />;
    }

    let defaultRate;
    if (activeDomainsMerchants) {
      defaultRate = this.getDefaultRate();
    }

    return (
      <div className="merchant-card">
        <LazyLoad
          placeholder={
            <img
              className="merchant-image"
              src={placeholder}
              alt="merchant placeholder"
            />
          }
        >
          <img
            className="merchant-image"
            ref={this.imageRef}
            src={this.getFeaturedImage()}
            alt={merchant.Name}
            onClick={this.handleMerchantClick}
            onError={this.handleImageError}
          />
        </LazyLoad>
        <div className="detail">
          <div className="left">
            <h3 onClick={this.handleMerchantClick}>{merchant.Name}</h3>
            <Link className="rate" to={`/stores/${merchant.ID}`}>
              {defaultRate ? `Up to ${defaultRate}` : 'See rates'}
            </Link>
          </div>
          <div className="right">
            <ShareContainer
              merchantName={merchant.Name}
              shortCode={merchant.ShortCode}
              shortURL={merchant.ShortURL}
            />
          </div>
        </div>
      </div>
    );
  }
}

MerchantCard.propTypes = {
  merchant: PropTypes.shape({
    ID: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    ShortURL: PropTypes.string.isRequired,
    ShortCode: PropTypes.string.isRequired,
    Images: PropTypes.array.isRequired,
  }),
  loadingComplete: PropTypes.bool.isRequired,
  activeDomainsMerchants: PropTypes.object,
};

export default MerchantCard;
