import React from 'react';

import './MobileDebug.css';

const MobileDebug = () => {
  const metadata = JSON.parse(window.WF.getEnvironmentMetadata());
  const senderToken = localStorage.getItem('senderToken') || '';
  const deviceToken = window.WF.getDeviceToken() || '';

  const handleOnboardingReset = () => {
    window.WF.resetOnboardingFlags();
  };

  const handleFcmTokenCopy = () => {
    window.WF.copyToClipboard(metadata.fcm_token);
  };

  const handleUuidCopy = () => {
    window.WF.copyToClipboard(metadata.uuid);
  };

  const handleDeviceTokenCopy = () => {
    window.WF.copyToClipboard(deviceToken);
  };

  const handleSenderTokenCopy = () => {
    window.WF.copyToClipboard(senderToken);
  };

  const handleOpenShareSheet = () => {
    window.WF.openShareSheet('https://wildlink.me');
  };

  const handleSendEmail = () => {
    window.WF.sendEmail(
      'testing subject',
      'checkout this test url: https://wildlink.me',
    );
  };

  const handleSendSMS = () => {
    window.WF.sendSMS('checkout this test url: https://wildlink.me');
  };

  return (
    <div className="mobile-debug">
      <h2>Developer</h2>
      <div className="metadata">
        <div>
          <b>App Version:</b>
          <span>{metadata.app_version}</span>
        </div>
        <div>
          <b>Bundle ID:</b>
          <span>{metadata.bundle_id}</span>
        </div>
        <div>
          <b>Device Model:</b>
          <span>{metadata.device_model}</span>
        </div>
        <div>
          <b>Operating System:</b>
          <span>{metadata.os}</span>
        </div>
        <div>
          <b>Onboarding Flags:</b>
          <span>
            <button onClick={handleOnboardingReset}>RESET</button>
          </span>
        </div>
        <div>
          <b>FCM Token:</b>
          <span onClick={handleFcmTokenCopy}>
            {metadata.fcm_token.substr(0, 6)}...
          </span>
        </div>
        <div>
          <b>UUID:</b>
          <span onClick={handleUuidCopy}>{metadata.uuid.substr(0, 6)}...</span>
        </div>

        <hr />

        <div>
          <b>Device Token:</b>
          <span onClick={handleDeviceTokenCopy}>
            {deviceToken.substr(0, 6)}...
          </span>
        </div>
        <div>
          <b>Sender Token:</b>
          <span onClick={handleSenderTokenCopy}>
            {senderToken.substr(0, 6)}...
          </span>
        </div>

        <hr />
      </div>
      <h2>Testing</h2>
      <div className="testing">
        <div>
          <b>Open Share Sheet:</b>
          <span>
            <button onClick={handleOpenShareSheet}>openShareSheet()</button>
          </span>
        </div>
        <div>
          <b>Send Email:</b>
          <span>
            <button onClick={handleSendEmail}>sendEmail()</button>
          </span>
        </div>
        <div>
          <b>Send SMS:</b>
          <span>
            <button onClick={handleSendSMS}>sendSMS()</button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileDebug;
