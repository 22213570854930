import { createSelector } from 'reselect';

import { sortObjectsByDate, formatDate } from '../helpers';
import { STRINGS as S } from '../constants';

export const earningsDetailSelector = (state) => state.earnings.earningsDetail;

export const shortUrlListSelector = (state) => state.clicks.shortUrlList;

export const shortURLSummarySelector = (state) => state.clicks.shortURLSummary;

export const activityFilterSelector = (state) => state.stats.activityFilter;

export const clickHistorySelector = (state) => state.clicks.clickHistory;

export const statsIsLoadingSelector = (state) => state.stats.isLoading;

export const statsErrorSelector = (state) => state.stats.error;

export const earningsSummarySelector = (state) =>
  state.earnings.earningsSummary;

export const readyAmountSelector = createSelector(
  earningsSummarySelector,
  (earningsSummary) => earningsSummary.ReadyAmount,
);

export const numClicksSelector = createSelector(
  clickHistorySelector,
  (clickHistory) => {
    let total = 0;
    for (const key in clickHistory) {
      total += clickHistory[key];
    }
    return total;
  },
);

export const numShortUrlsSelector = createSelector(
  shortURLSummarySelector,
  (shortURLSummary) => shortURLSummary.CreatedCount,
);

// select number of earnings from earnings detail that is not negative
export const numEarningsSelector = createSelector(
  earningsDetailSelector,
  (earningsDetail) =>
    earningsDetail.reduce(
      (acc, current) => (parseFloat(current.Amount) >= 0 ? acc + 1 : acc),
      0,
    ),
);

// select number of all commissions unrolled from earnings detail
export const numCommissionsSelector = createSelector(
  earningsDetailSelector,
  (earningsDetail) =>
    earningsDetail.reduce(
      (acc, current) => acc + current.CommissionIDs.length,
      0,
    ),
);

export const activitySelector = createSelector(
  shortUrlListSelector,
  earningsDetailSelector,
  // only show 100 most recent of each activity
  (shortUrlList = [], earningsDetail = []) => [
    ...shortUrlList,
    ...earningsDetail,
  ],
);

export const reverseChronologicalActivitySelector = createSelector(
  activitySelector,
  (activity) => sortObjectsByDate(activity),
);

export const filteredActivitySelector = createSelector(
  reverseChronologicalActivitySelector,
  activityFilterSelector,
  (activity, filter) => {
    switch (filter) {
      case S.ALL_ACTIVITY:
        return activity;
      case S.EARNINGS_ACTIVITY:
        return activity.filter((a) => typeof a.Amount !== 'undefined');
      case S.SHORT_URL_ACTIVITY:
        return activity.filter((a) => typeof a.URL !== 'undefined');
      default:
        return activity;
    }
  },
);

export const shortUrlGraphDataSelector = createSelector(
  shortUrlListSelector,
  (shortUrlList) => {
    let collapsedDates = {};
    let formattedData = [];
    let count = 0;

    // data currently comes in reverse chronological order
    const chronologicalData = shortUrlList.slice().reverse();

    // collapse dates
    for (let i = 0; i < chronologicalData.length; i++) {
      const date = formatDate(chronologicalData[i].Date);
      if (collapsedDates[date]) {
        collapsedDates[date] += 1;
      } else {
        collapsedDates[date] = 1;
      }
    }

    // insert data in the format we want
    Object.keys(collapsedDates).forEach((date) => {
      count += collapsedDates[date];
      formattedData.push({
        date: new Date(date).getTime(),
        count,
      });
    });

    // insert todays date if it is not there
    if (
      shortUrlList.length &&
      formatDate(formattedData[formattedData.length - 1].date) !== formatDate()
    ) {
      formattedData.push({
        date: new Date().getTime(),
        count,
      });
    }

    // give a zero starting point if there is only one data point
    if (formattedData.length === 1) {
      formattedData.unshift({
        date: 0,
        count: 0,
      });
    }

    return formattedData;
  },
);

export const earningsGraphDataSelector = createSelector(
  earningsDetailSelector,
  (earningsDetail) => {
    let collapsedDates = {};
    let formattedData = [];
    let currentCount = 0;
    let currentTotal = 0;

    // data currently comes in reverse chronological order
    const chronologicalData = earningsDetail.slice().reverse();

    // collapse dates
    for (let i = 0; i < chronologicalData.length; i++) {
      const date = formatDate(chronologicalData[i].Date);
      const amount = parseFloat(chronologicalData[i].Amount);
      if (typeof collapsedDates[date] === 'undefined') {
        collapsedDates[date] = {
          count: 1,
          amount,
        };
      } else {
        collapsedDates[date].count += 1;
        collapsedDates[date].amount += amount;
      }
    }

    // insert data in the format we want
    Object.keys(collapsedDates).forEach((date) => {
      currentCount += collapsedDates[date].count;
      currentTotal += collapsedDates[date].amount;
      formattedData.push({
        date: new Date(date).getTime(),
        count: currentCount,
        amount: currentTotal,
      });
    });

    // insert todays date if it is not there
    if (
      earningsDetail.length &&
      formatDate(formattedData[formattedData.length - 1].date) !== formatDate()
    ) {
      formattedData.push({
        date: new Date().getTime(),
        count: currentCount,
        amount: currentTotal,
      });
    }

    // give a zero starting point if there is only one data point
    if (formattedData.length === 1) {
      formattedData.unshift({
        date: 0,
        count: 0,
        amount: 0,
      });
    }

    return formattedData;
  },
);

export const clicksGraphDataSelector = createSelector(
  clickHistorySelector,
  (clickHistory) => {
    let formattedData = [];
    let count = 0;

    // insert data in the format we want
    Object.keys(clickHistory).forEach((date) => {
      count += clickHistory[date];
      formattedData.push({
        date: new Date(date).getTime(),
        count,
      });
    });

    // insert todays date if it is not there
    if (
      formattedData.length &&
      formatDate(formattedData[formattedData.length - 1].date) !== formatDate()
    ) {
      formattedData.push({
        date: new Date().getTime(),
        count,
      });
    }

    // give a zero starting point if there is only one data point
    if (formattedData.length === 1) {
      formattedData.unshift({
        date: 0,
        count: 0,
      });
    }

    return formattedData;
  },
);

export const cloudProfileSelector = (state) => state.earnings.cloudProfile;
