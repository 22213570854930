import { connect } from 'react-redux';

import {
  merchantCategoriesSelector,
  allCategoryMerchantsSuccessSelector,
  allCategoryMerchantsErrorSelector,
} from '../../../selectors/merchants';

import { getMerchantCategories } from '../../../actions/merchants';

import MerchantCategories from './MerchantCategories';

const mapStateToProps = (state) => ({
  merchantCategories: merchantCategoriesSelector(state),
  success: allCategoryMerchantsSuccessSelector(state),
  error: allCategoryMerchantsErrorSelector(state),
});

export const mapActionsToProps = {
  getMerchantCategories,
};

export default connect(mapStateToProps, mapActionsToProps)(MerchantCategories);
