import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loading from '../../components/Loading/Loading';
import NetworkError from '../../components/Error/NetworkError/NetworkError';

import './ExtensionWelcome.css';

class ExtensionWelcome extends Component {
  state = {
    isLoading: false,
    error: null,
  };

  addInstallReferrer = async () => {
    const { addDeviceAcquisition } = this.props;
    const referrer = window.localStorage.getItem('referrer');

    if (referrer) {
      this.setState({ isLoading: true });
      try {
        const response = await addDeviceAcquisition(referrer);
        if (response.status !== 'success') {
          throw new Error('error adding device acquisition');
        }
        this.setState({ isLoading: false });
        window.localStorage.removeItem('referrer');
      } catch (error) {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      }
    }
  };

  retryAddInstallReferrer = async () => {
    this.setState({
      isLoading: true,
      error: null,
    });
    await this.addInstallReferrer();
  };

  setSkipLogin = () => this.setState({ skipLogin: true });

  async componentDidMount() {
    await this.addInstallReferrer();
  }

  render() {
    const { error, isLoading } = this.state;

    const loginLinkOptions = {
      pathname: '/settings',
    };

    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return (
        <NetworkError onRetry={this.retryAddInstallReferrer} error={error} />
      );
    }

    return (
      <div className="extension-welcome">
        <h1>Wildlink successfully installed</h1>

        <div className="extension-video">
          <div>
            Now, learn how to get started using Wildlink for Chrome by watching
            this short video.
          </div>
          <video controls>
            <source
              src="https://storage.googleapis.com/wildlink/web/support/wildlink%20chrome%20ext%20walkthru%20-%20post%20install.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div>
          Take a moment to <Link to={loginLinkOptions}>log in</Link> with your
          phone number so you get credit for your future earnings.
        </div>
      </div>
    );
  }
}

ExtensionWelcome.propTypes = {
  deviceToken: PropTypes.string.isRequired,
  senderToken: PropTypes.string.isRequired,
  addDeviceAcquisition: PropTypes.func.isRequired,
};

export default ExtensionWelcome;
