import React from 'react';
import PropTypes from 'prop-types';

import facebookIcon from '../../static/images/share/facebook.png';

const Facebook = ({ vanity }) => {
  const facebookShare = `https://www.facebook.com/sharer.php?u=${vanity}`;
  const handleClick = () => window.WF.openUrl(facebookShare);

  return (
    <img
      className="icon"
      src={facebookIcon}
      alt="facebook"
      onClick={handleClick}
    />
  );
};

Facebook.propTypes = {
  vanity: PropTypes.string,
};

export default Facebook;
