import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

import rootReducer from './rootReducer';
import initialState from './initialState';
// middleware
import api from './middleware/api';
import badAuth from './middleware/badAuth';

export const history = createBrowserHistory();

const enhancers = [];
const middleware = [thunk, badAuth, api];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState(), composedEnhancers);

export default store;
