import REDUX from './redux';

// local uses process.env and live uses window.env
export const SERVER_ENDPOINT =
  process.env.REACT_APP_SERVER_URI || window.env.serverUri;

export const URLS = {
  VANITY_REDIRECT: process.env.REACT_APP_VANITY || window.env.vanity,
  CONNECT_PAYPAL_URL:
    process.env.REACT_APP_CONNECT_PAYPAL_URL || window.env.connectPaypalUri,
  CONTACT_PAGE: 'https://www.wildfire-corp.com/wildlink-support',
  MISSING_EARNINGS_PAGE:
    'https://support.wildfire-corp.com/for-wildlink-users/i-know-that-a-transaction-occurred.-why-didnt-i-earn-a-reward',
  SUPPORT_PAGE: 'https://support.wildfire-corp.com/for-wildlink-users',
  TERMS_PAGE:
    'https://support.wildfire-corp.com/for-wildlink-users/terms-of-use',
  PRIVACY_PAGE:
    'https://support.wildfire-corp.com/for-wildlink-users/privacy-policy',
  COMMISSION_URL_BASE: 'https://storage.googleapis.com/wfdp/v3/',
};

export const DATA_URLS = {
  ACTIVE_DOMAINS:
    process.env.REACT_APP_ACTIVE_DOMAIN_URI || window.env.activeDomainUri,
};

export const R = {
  ...REDUX,
};

export const SHORT_MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const COLORS = {
  PRIMARY_COLOR: '#2f9dc5',
  SECONDARY_COLOR_LIGHT: '#b4d2d6',
  SECONDARY_COLOR_DARK: '#302b5a',
};

export const GTAG_ID_MAP = {
  web: 'UA-100475901-1',
  ios: 'UA-109711780-28',
  android: 'UA-109711780-27',
};

export const GTAG_ID = GTAG_ID_MAP[window.WF.getEnvironment()];

export const TITLE_MAP = {
  '/stores': 'stores',
  '/stats': 'stats',
  '/settings': 'settings',
  '/community-activity': 'community activity',
};

// various strings
export const STRINGS = {
  ALL_ACTIVITY: 'ALL_ACTIVITY',
  EARNINGS_ACTIVITY: 'EARNINGS_ACTIVITY',
  SHORT_URL_ACTIVITY: 'SHORT_URL_ACTIVITY',
  GENERATED: 'GENERATED',
  CLICKS: 'CLICKS',
  PURCHASES: 'PURCHASES',
  EARNINGS: 'EARNINGS',
};
