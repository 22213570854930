import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Verizon from '../../pages/Onboarding/Verizon/Verizon';
import Visa from '../../pages/Onboarding/Visa/Visa';

const ThirdParty = () => {
  return (
    <Switch>
      <Route path="/onboarding/verizon" exact component={Verizon} />
      <Route path="/onboarding/visa" exact component={Visa} />
    </Switch>
  );
};

export default ThirdParty;
