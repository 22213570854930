import { R } from '../constants';

export const defaultErrors = {
  error: null,
  badAuth: false,
};

const reducer = (state = defaultErrors, action) => {
  switch (action.type) {
    case R.ERROR:
      return {
        ...state,
        error: action.error.message,
      };
    case R.SET_BAD_AUTH:
      return {
        ...state,
        badAuth: true,
      };
    case R.SET_DEVICE_TOKEN: {
      return {
        ...state,
        badAuth: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
