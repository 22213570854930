import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MerchantCategory from '../MerchantCategory/MerchantCategory';
import Loading from '../../Loading/Loading';
import NetworkError from '../../Error/NetworkError/NetworkError';

import './MerchantCategories.css';

class MerchantCategories extends Component {
  componentDidMount() {
    const { getMerchantCategories, success } = this.props;
    if (!success) {
      getMerchantCategories();
    }
  }

  render() {
    const { getMerchantCategories, merchantCategories, error } = this.props;

    if (error) {
      return <NetworkError onRetry={getMerchantCategories} error={error} />;
    }

    if (merchantCategories.length === 0) {
      return <Loading />;
    }

    return (
      <div className="merchant-categories">
        {merchantCategories.map((category) => (
          <MerchantCategory key={category.name} category={category} />
        ))}
      </div>
    );
  }
}

MerchantCategories.propTypes = {
  getMerchantCategories: PropTypes.func.isRequired,
  merchantCategories: PropTypes.array.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default MerchantCategories;
