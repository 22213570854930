import { R } from '../constants';

const badAuth = (store) => (next) => (action) => {
  const state = store.getState();
  // skip if bad auth
  if (
    state.errors.badAuth &&
    // allow getting new device token
    action.type !== R.WF_FETCH &&
    action.method !== 'POST' &&
    // allow setting new device token
    action.type !== R.SET_DEVICE_TOKEN
  ) {
    return;
  }
  return next(action);
};

export default badAuth;
