import { faSearch } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Loading from '../../Loading/Loading';
import './SearchMerchants.css';

class SearchMerchants extends Component {
  searchInput = React.createRef();

  debounceSetSearchQueryString = debounce((value) => {
    this.setSearchQueryString(value);
  }, 300);

  debounceSearchMerchantsAnalytics = debounce((value) => {
    window.gtag('event', 'search merchants', {
      event_category: 'search',
      event_label: value,
    });
  }, 2000);

  state = {
    searchQuery: this.props.searchQuery || '',
  };

  componentDidMount() {
    this.searchByQueryString();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.key !== prevProps.location.key) {
      this.searchByQueryString();
    }
  }

  /* viewType = () => {
    const { listMerchants } = this.props;

    if (listMerchants) {
      return (
        <div>
          <FontAwesomeIcon icon={ faTh } size="1x" />
          Grid
        </div>
      );
    }

    return (
      <div>
        <FontAwesomeIcon icon={ faThList } size="1x" />
        List
      </div>
    );
  }; */

  getSearchQueryString = () => {
    const { location } = this.props;
    const params = queryString.parse(location.search);

    return params.search;
  };

  setSearchQueryString = (search) => {
    const { history } = this.props;
    const params = queryString.stringify({ search });

    history.replace({ search: params });
  };

  searchByQueryString = () => {
    const {
      searchQuery,
      setMerchantSearchQuery,
      clearSearchedMerchants,
      searchMerchants,
    } = this.props;
    const search = this.getSearchQueryString();

    if (typeof search === 'undefined') {
      setMerchantSearchQuery('');
      clearSearchedMerchants();

      return;
    }

    if (search.length <= 2) {
      clearSearchedMerchants();
    } else if (search !== searchQuery) {
      searchMerchants(search);
    }

    setMerchantSearchQuery(search);
    this.setState({ searchQuery: search });
  };

  handleInput = () => {
    const { clearSearchedMerchants } = this.props;

    const { value } = this.searchInput.current;

    this.setState({ searchQuery: value });
    this.debounceSetSearchQueryString(value);

    if (value.length > 2) {
      this.debounceSearchMerchantsAnalytics(value);
    } else {
      clearSearchedMerchants();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const searchInput = this.searchInput.current;
    searchInput.blur();
  };

  render() {
    const { isLoading, searchedMerchants } = this.props;
    const { searchQuery } = this.state;

    return (
      <div className="search-merchants">
        {isLoading && searchedMerchants.length ? (
          <Loading size="1x" color="dark-grey" />
        ) : (
          <FontAwesomeIcon icon={faSearch} size="1x" />
        )}
        <form role="search" onSubmit={this.handleSubmit}>
          <input
            ref={this.searchInput}
            value={searchQuery}
            type="search"
            label="search"
            placeholder="Search Thousands of Stores"
            onChange={this.handleInput}
          />
        </form>
        {/* <div className='list-merchants' onClick={toggleListMerchants}>
          {this.viewType()}
        </div> */}
      </div>
    );
  }
}

SearchMerchants.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  searchMerchants: PropTypes.func.isRequired,
  clearSearchedMerchants: PropTypes.func.isRequired,
  setMerchantSearchQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchedMerchants: PropTypes.array.isRequired,
  // toggleListMerchants: PropTypes.func.isRequired,
  // listMerchants: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default SearchMerchants;
