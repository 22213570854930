export const extension = {
  ID: 'ipldncgiiglfcmcookhlkeicoinpkdni',
  ID_INTERNAL: 'jicgbcdhimapfbpeodkgglnjlcnopgha',
  IS_EXTENSION_LOGIN_VALID: 'IS_EXTENSION_LOGIN_VALID',
  VALIDATE_SUCCESS: 'VALIDATE_SUCCESS',
  MAKE_SENDER_FROM_PAYPAL: 'MAKE_SENDER_FROM_PAYPAL',
  CLEAR_STORAGE: 'CLEAR_STORAGE',
  PING: 'PING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  canRunExtension: async () => {
    try {
      if (
        typeof window.chrome === 'undefined' ||
        typeof window.chrome.runtime === 'undefined'
      )
        return false;
      const message = {
        status: extension.PING,
      };
      const response = await extension.sendMessage(message);
      return response?.status === extension.SUCCESS;
    } catch (_) {
      return false;
    }
  },
  sendMessage: async (message) => {
    return new Promise((resolve, reject) => {
      window.chrome.runtime.sendMessage(extension.ID, message, (response) => {
        if (window.chrome.runtime.lastError) {
          return reject(window.chrome.runtime.lastError);
        }
        resolve(response);
      });
    });
  },
  clear: async () => {
    if (!(await extension.canRunExtension())) return false;
    const message = {
      status: extension.CLEAR_STORAGE,
    };
    const response = await extension.sendMessage(message);
    return response?.status === extension.SUCCESS;
  },
  makeSenderFromPaypalExtension: async (sender) => {
    if (!(await extension.canRunExtension())) return false;
    const message = {
      status: extension.MAKE_SENDER_FROM_PAYPAL,
      payload: sender,
    };
    const response = await extension.sendMessage(message);
    return response?.status === extension.SUCCESS;
  },
  isExtensionLoginValid: async (sender) => {
    if (!(await extension.canRunExtension())) return false;
    const message = {
      status: extension.IS_EXTENSION_LOGIN_VALID,
      payload: sender,
    };
    const response = await extension.sendMessage(message);
    return response?.status === extension.SUCCESS && !!response?.payload;
  },
};
