import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Button.css';

const Button = ({
  size,
  children,
  disabled = false,
  onClick,
  gradient = false,
}) => {
  const buttonClass = classNames('wf-button', {
    [size]: true,
    disabled,
    gradient,
  });

  return (
    <button disabled={disabled} className={buttonClass} onClick={onClick}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  gradient: PropTypes.bool,
};

export default Button;
