import uuidv4 from 'uuid/v4';

// lastGitCommit.js is generated at build time
let lastGitCommit;
try {
  lastGitCommit = require('../helpers/lastGitCommit');
} catch (e) {
  lastGitCommit = { hash: '_' };
}

export const sleep = async (time) => new Promise((r) => setTimeout(r, time));

export const getUuid = async (retries = 5) => {
  let uuid = '';
  if (isAndroid() && isMobileApp()) {
    try {
      const metadata = JSON.parse(window.WF.getEnvironmentMetadata());
      uuid = metadata.uuid;
      if (!uuid && retries) {
        await sleep(500);
        return await getUuid(retries - 1);
      }
    } catch (error) {
      console.error(error);
      uuid = '';
    }
  } else {
    uuid = localStorage.getItem('uuid');
  }
  localStorage.setItem('uuid', uuid);
  return uuid;
};

export const getOs = () => {
  const { platform } = window.navigator;
  if (platform.indexOf('Win') > -1) return 'win';
  if (platform.indexOf('Mac') > -1) return 'mac';
  if (platform.indexOf('Linux') > -1) return 'linux';
  return false;
};

export const isIOS = () =>
  window.navigator.userAgent.match(/ipad|iphone|ipod/i);

export const isIOSApp = () => window.WF.getEnvironment() === 'ios';

export const isAndroid = () => window.navigator.userAgent.match(/android/i);

export const isIE = () => /*@cc_on!@*/ false || !!document.documentMode;

export const isMobileApp = () => window.WF.getEnvironment() !== 'web';

export const isMainHome = () => {
  const mainHomePathnames = ['/', '/about'];
  return mainHomePathnames.indexOf(window.location.pathname) >= 0;
};

export const isThirdParty = () => {
  const thirdPartyPathnames = new Set([
    '/onboarding/verizon',
    '/onboarding/visa',
  ]);
  return thirdPartyPathnames.has(window.location.pathname);
};

export const loadScript = (src, callback) => {
  const tag = document.createElement('script');
  tag.async = false;
  if (typeof callback === 'function') {
    tag.onload = callback;
  }
  document.head.appendChild(tag);
  tag.src = src;
};

export const isEmpty = (object) =>
  Object.keys(object).length === 0 && object.constructor === Object;

export const sortObjectsByDate = (objects) =>
  objects.sort((a, b) => {
    const aDate = new Date(a.Date);
    const bDate = new Date(b.Date);
    if (aDate.getTime() > bDate.getTime()) {
      return -1;
    }
    if (aDate.getTime() < bDate.getTime()) {
      return 1;
    }
    return 0;
  });

export const setTitle = (title = 'Your Opinion Has Value') =>
  (document.title = `Wildlink | ${title}`);

export const formatDate = (dateString) => {
  let date;
  if (typeof dateString === 'undefined') {
    date = new Date();
  } else {
    date = new Date(dateString);
  }
  // safari requires zero padding
  let month = `${date.getMonth() + 1}`;
  if (month.length < 2) {
    month = `0${month}`;
  }
  let day = `${date.getDate()}`;
  if (day.length < 2) {
    day = `0${day}`;
  }
  const year = date.getFullYear();
  return `${year}/${month}/${day}`;
};

export const wfUserAgent = () =>
  `${lastGitCommit.hash}-webview-${window.WF.getEnvironment()}`;

// injected by CRA
export const isLocalDev = process.env.NODE_ENV === 'development';

export const makeJumpURL = (destination) => {
  const basePath = isMobileApp() ? `https://${window.location.hostname}` : '';
  return `${basePath}/redirect?goto=${encodeURI(destination)}`;
};

export const getURLParams = () => {
  const params = {};
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    const key = pair[0];
    const value = pair[1];
    if (typeof value !== 'undefined') {
      params[key] = value;
    }
  }
  return params;
};

export const timeout = (ms, promise) => {
  return new Promise(function(resolve, reject) {
    setTimeout(() => {
      reject(new Error('timeout'));
    }, ms);
    promise.then(resolve, reject);
  });
};

export const timeoutFetch = (URL, options) =>
  timeout(10000, fetch(URL, options));

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem('phoneNumber');
  localStorage.removeItem('deviceToken');
  localStorage.removeItem('deviceID');
  localStorage.removeItem('deviceKey');
  localStorage.removeItem('senderId');
  localStorage.removeItem('senderToken');
  localStorage.removeItem('uuid');
};

export const parseRate = (rate) => {
  const rateAmount = parseFloat(rate.Amount);

  if (rate.Kind === 'PERCENTAGE') {
    const defaultPercentageRate = rateAmount.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
    return `${defaultPercentageRate}%`;
  } else if (rate.Kind === 'FLAT') {
    return rateAmount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: rate.Currency,
    });
  }
};

export const parseActiveDomainsMerchant = (merchant) => {
  const { MaxRate, Name } = merchant;
  return {
    name: Name,
    parsedRate: parseRate(MaxRate),
    kind: MaxRate.Kind,
  };
};

export const prepareActiveDomains = (commissionsData) => {
  return commissionsData.reduce((result, { Merchant }) => {
    // prefer percentage rate, if no percentage rate then pick highest USD amount, if no USD amount pick highest amount
    if (!result[Merchant.ID] && Merchant.MaxRate) {
      result[Merchant.ID] = Merchant;
    } else if (Merchant.MaxRate) {
      // merchant from previous iteration
      let tempMerchant = result[Merchant.ID];

      const {
        MaxRate: {
          Amount: tempMerchantRateAmount,
          Kind: tempMerchantRateKind,
          Currency: tempMerchantRateCurrency,
        },
      } = tempMerchant;

      // merchant from the current iteration
      const {
        MaxRate: {
          Amount: currentMerchantRateAmount,
          Kind: currentMerchantRateKind,
          Currency: currentMerchantRateCurrency,
        },
      } = Merchant;

      // is new merchant has bigger amount
      const isNewAmountBigger =
        parseFloat(tempMerchantRateAmount) <
        parseFloat(currentMerchantRateAmount);

      // prefer percentage rate over flat rates
      if (
        currentMerchantRateKind === 'PERCENTAGE' &&
        tempMerchantRateKind === 'PERCENTAGE' &&
        isNewAmountBigger
      ) {
        tempMerchant = Merchant;
      } else if (
        currentMerchantRateKind === 'FLAT' &&
        tempMerchantRateKind !== 'PERCENTAGE'
      ) {
        // prefer USD currency over other currencies
        if (
          (tempMerchantRateCurrency === 'USD' &&
            currentMerchantRateCurrency === 'USD' &&
            isNewAmountBigger) ||
          (tempMerchantRateCurrency !== 'USD' &&
            currentMerchantRateCurrency !== 'USD' &&
            isNewAmountBigger) ||
          (tempMerchantRateCurrency !== 'USD' &&
            currentMerchantRateCurrency === 'USD')
        ) {
          tempMerchant = Merchant;
        }
      }

      // store final result into reduce function result
      result[Merchant.ID] = tempMerchant;
    }

    return result;
  }, {});
};

export const parseActiveDomains = (activeDomains) => {
  const preparedActiveDomainsMerchants = prepareActiveDomains(activeDomains);
  // parse merchant into desired shape
  Object.keys(preparedActiveDomainsMerchants).forEach((merchantKey) => {
    preparedActiveDomainsMerchants[merchantKey] = parseActiveDomainsMerchant(
      preparedActiveDomainsMerchants[merchantKey],
    );
  });

  return preparedActiveDomainsMerchants;
};

export const sortCommissionRates = (commissionRates) => {
  const percentages = commissionRates.filter(
    (rate) => rate.Kind === 'PERCENTAGE',
  );
  const flats = commissionRates.filter((rate) => rate.Kind === 'FLAT');
  const sorter = (a, b) => parseFloat(b.Amount) - parseFloat(a.Amount);
  return [...percentages.sort(sorter), ...flats.sort(sorter)];
};

export const parseCommissionRates = (commissionRates) =>
  sortCommissionRates(commissionRates).map((rate) => {
    const name = rate.DisplayName === '' ? rate.Name : rate.DisplayName;

    return {
      name,
      parsedRate: parseRate(rate),
      currency: rate.Currency,
      isDefault: rate.IsDefault,
      kind: rate.Kind,
    };
  });

export const isDevSite = () =>
  window.location.hostname.startsWith('dev') ||
  window.location.hostname === '0.0.0.0';
