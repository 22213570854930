import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { faCircleNotch } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Loading.css';

const Loading = ({ size = '6x', color = 'grey', noFill = false }) => {
  const loadingClass = classNames('loading', {
    [color]: true,
    noFill,
  });

  return (
    <div className={loadingClass}>
      <FontAwesomeIcon icon={faCircleNotch} size={size} spin />
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  noFill: PropTypes.bool,
};

export default Loading;
