import React from 'react';
import PropTypes from 'prop-types';

import smsIcon from '../../static/images/share/sms.png';

const SMS = ({ vanity }) => {
  const body = `Wildlink ${vanity}`;
  const handleClick = () => window.WF.sendSMS(body);

  return <img className="icon" src={smsIcon} alt="sms" onClick={handleClick} />;
};

SMS.propTypes = {
  vanity: PropTypes.string,
};

export default SMS;
