import { connect } from 'react-redux';

import {
  merchantCategoriesSelector,
  userSeeNewFeatureModalSelector,
} from '../../selectors';

import {
  setMerchantSearchQuery,
  clearSearchedMerchants,
  forceUserSeeNewFeatureModal,
} from '../../actions';

import Stores from './Stores';

const mapStateToProps = (state) => ({
  merchantCategories: merchantCategoriesSelector(state),
  shouldUserSeeNewFeatureModal: userSeeNewFeatureModalSelector(state),
});

const mapActionsToProps = {
  setMerchantSearchQuery,
  clearSearchedMerchants,
  forceUserSeeNewFeatureModal,
};

export default connect(mapStateToProps, mapActionsToProps)(Stores);
