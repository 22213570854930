import { connect } from 'react-redux';

import { deviceTokenSelector, senderTokenSelector } from '../../selectors';

import { addDeviceAcquisition } from '../../actions';

import ExtensionWelcome from './ExtensionWelcome';

const mapStateToProps = (state) => ({
  deviceToken: deviceTokenSelector(state),
  senderToken: senderTokenSelector(state),
});

const mapActionsToProps = {
  addDeviceAcquisition,
};

export default connect(mapStateToProps, mapActionsToProps)(ExtensionWelcome);
