import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { faTimes } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CookieDisclaimer.scss';

const CookieDisclaimer = ({ history }) => {
  const [hideDisclaimer, setHideDisclaimer] = useState(false);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      // do not accept on redirect
      // do not do work if already accepted
      if (history.action !== 'REPLACE' && !hideDisclaimer) {
        acceptDisclaimer();
      }
    }
  }, [hideDisclaimer, history.action]);

  const acceptDisclaimer = () => {
    localStorage.setItem('acceptCookies', '1');
    setHideDisclaimer(true);
  };

  if (hideDisclaimer) {
    return null;
  }

  return (
    <div className="cookie-disclaimer">
      <div>
        Wildlink cookie policy: We use cookie-like technology to personalize
        content and analyze usage to improve our product. Visit our{' '}
        <Link to="/privacy">Privacy Policy</Link> to learn more about our data
        collection practices. You agree to the use of this technology by closing
        this banner, by clicking a link or by continuing to browse.
      </div>
      <div>
        <FontAwesomeIcon
          className="accept"
          icon={faTimes}
          size="lg"
          onClick={acceptDisclaimer}
        />
      </div>
    </div>
  );
};

CookieDisclaimer.propTypes = {
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
  }),
};

export default withRouter(CookieDisclaimer);
