import { SERVER_ENDPOINT, R } from '../constants';
import { setBadAuth } from '../actions';
import { history } from '../store';

import { wfUserAgent, timeoutFetch, sleep } from '../helpers';

const getSenderToken = () => {
  const senderToken = localStorage.getItem('senderToken');
  return senderToken ? senderToken : '';
};

const retryGetDeviceToken = async (retries = 5) => {
  const deviceToken = window.WF.getDeviceToken();
  if (window.WF.getEnvironment() !== 'android') {
    return deviceToken;
  }
  if (!deviceToken && retries) {
    await sleep(500);
    return retryGetDeviceToken(retries - 1);
  }
  return deviceToken;
};

export const wfFetch = async (method, path, body) => {
  const options = {
    method,
    headers: {
      'X-WF-UserAgent': wfUserAgent(),
      'X-WF-DeviceToken': await retryGetDeviceToken(),
      'X-WF-SenderToken': getSenderToken(),
      'Content-Type': 'application/json',
    },
  };
  if (typeof body !== 'undefined') {
    options.body = JSON.stringify(body);
  }

  const response = await timeoutFetch(`${SERVER_ENDPOINT}${path}`, options);
  return response;
};

const API = (store) => (next) => async (action) => {
  const { type, method, path, body } = action;

  if (type === R.WF_FETCH) {
    const response = await wfFetch(method, path, body);

    if (response.status >= 500) {
      return Promise.reject(new Error('wf api failure'));
    }

    if (response.status === 403) {
      // make sure badAuth is set only once
      if (!store.getState().errors.badAuth) {
        await next(setBadAuth());
        history.push('/login');
        return;
      } else {
        // skip the rest
        return;
      }
    }

    // Edgecase on android on update, we receive a device token
    // that already has been created previously, which explains why there is a 409
    // if we send back the device token that android has, it fixes the broken loop
    if (
      response.status === 409 &&
      path === '/api/device' &&
      window.WF.getEnvironment() === 'android'
    ) {
      return {
        deviceToken: await retryGetDeviceToken(),
      };
    }

    const data = await response.json();
    return data;
  }

  return next(action);
};

export default API;
