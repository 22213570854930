import React from 'react';
import PropTypes from 'prop-types';

import { history } from '../../store';

const SearchBanner = ({ banner }) => {
  const handleBannerClick = () => {
    window.gtag('event', 'banner click', {
      event_category: 'stores',
      event_label: `${banner.description}`,
    });
    history.push({
      pathname: '/stores',
      search: banner.search,
    });
  };

  if (typeof banner.image === 'undefined') {
    return null;
  }

  return (
    <div className="banner" onClick={handleBannerClick}>
      <img src={banner.image} alt={`${banner.description}`} />
    </div>
  );
};

SearchBanner.propTypes = {
  banner: PropTypes.shape({
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
};

export default SearchBanner;
