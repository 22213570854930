import React, { Component } from 'react';

import './UnsupportedBrowser.css';

class UnsupportedBrowser extends Component {
  componentDidMount() {
    window.gtag('event', 'load', {
      event_label: 'unsupported browser',
    });
  }

  render() {
    return (
      <div className="unsupported-browser">
        <h1>Unsupported Browser</h1>
        <div>
          It looks like you are using an unsupported browser.
          <br />
          Please use the latest version of any of the following browsers:
          <ul>
            <li>
              <a href="//www.google.com/chrome/">Google Chrome</a>
            </li>
            <li>
              <a href="//www.mozilla.org/en-US/firefox/new/">Mozilla Firefox</a>
            </li>
            <li>
              <a href="//www.apple.com/safari/">Apple Safari</a>
            </li>
            <li>
              <a href="//www.microsoft.com/en-us/windows/microsoft-edge/">
                Microsoft Edge
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default UnsupportedBrowser;
