import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { history } from './store';
import { isMobileApp, isIE, isMainHome, isThirdParty } from './helpers';

import Navigation from './components/Navigation/Navigation';
import MainHome from './routers/MainHome/MainHome';
import Footer from './components/Footer/Footer';
import HeaderContainer from './components/Header/HeaderContainer';
import MainContainer from './routers/Main/MainContainer';
import UnsupportedBrowser from './components/UnsupportedBrowser/UnsupportedBrowser';
import CookieDisclaimer from './components/CookieDisclaimer/CookieDisclaimer';

import './App.css';
import ThirdParty from './routers/ThirdParty/ThirdParty';

class App extends Component {
  state = {
    showCookieDisclaimer:
      !isMobileApp() && !Boolean(localStorage.getItem('acceptCookies')),
  };

  componentDidMount() {
    if (isMobileApp()) {
      document.body.style.background = '#fff';

      if (isMainHome()) {
        history.replace('/stores');
      }
    }
  }

  render() {
    const { showCookieDisclaimer } = this.state;

    if (isIE()) {
      return <UnsupportedBrowser />;
    }

    if (isThirdParty()) {
      return <ThirdParty />;
    }

    if (isMainHome()) {
      return (
        <div className="home-app">
          <Navigation />
          <MainHome />
          {showCookieDisclaimer && <CookieDisclaimer />}
          <Footer />
        </div>
      );
    }

    return (
      <div className="app">
        <HeaderContainer location={this.props.location} />
        <MainContainer location={this.props.location} />
        {showCookieDisclaimer && <CookieDisclaimer />}
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired,
};

export default App;
