import React from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { URLS } from '../../../../constants';

import './ConnectWithPaypal.scss';

const ConnectWithPaypal = ({ canConnect }) => {
  const connectWithPaypal = () => {
    const url = new URL(URLS.CONNECT_PAYPAL_URL);
    url.searchParams.append(
      'redirect_uri',
      window.location.origin + window.location.pathname,
    );
    window.location.href = url.toString();
  };

  const connectWithPaypalClass = classNames({
    'connect-with-paypal': true,
    ready: canConnect,
  });

  return (
    <button
      className={connectWithPaypalClass}
      onClick={canConnect ? connectWithPaypal : undefined}
    >
      <FontAwesomeIcon icon={faPaypal} size={'lg'} />
      Connect with PayPal
    </button>
  );
};

export default ConnectWithPaypal;
