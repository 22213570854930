import React from 'react';
import { history } from '../../../store';
import PropTypes from 'prop-types';

import CommissionRate from '../../../components/CommissionRate/CommissionRate';
import MerchantLogoPlaceholder from '../../../components/Merchants/MerchantListItem/MerchantLogoPlaceholder/MerchantLogoPlaceholder';
import ScrollToTopOnMount from '../../../components/Utils/ScrollToTopOnMount';
import { makeJumpURL } from '../../../helpers';

import './StoreDetail.scss';

class StoreDetail extends React.Component {
  back = () => {
    if (history.location.key) {
      history.goBack();
    } else {
      history.push('/stores');
    }
  };

  getLogoImage = () => {
    const { merchant } = this.props;
    if (merchant && merchant.Images) {
      for (let i = 0; i < merchant.Images.length; i++) {
        const image = merchant.Images[i];
        if (image.Kind === 'LOGO') {
          return image.URL;
        }
      }
    }
    return false;
  };

  handleEmailSupport = () =>
    window.WF.sendEmail('No Earning Rate Details', '', 'support@wildlink.me');

  async componentDidMount() {
    const {
      getMerchantCommissionRates,
      match,
      getMerchant,
      merchant,
      commissionRates,
    } = this.props;
    const { merchantId } = match.params;

    if (typeof commissionRates === 'undefined') {
      await getMerchantCommissionRates(merchantId);
    }

    if (typeof merchant === 'undefined') {
      await getMerchant(merchantId);
    }
  }

  handleMerchantClick = () => {
    const { merchant } = this.props;
    window.WF.openUrl(makeJumpURL(merchant.ShortURL));
  };

  render() {
    const { commissionRates, merchant } = this.props;
    const logo = this.getLogoImage();

    return (
      <div className="store-detail">
        <ScrollToTopOnMount />
        <div className="back" onClick={this.back}>
          Back
        </div>
        <div className="merchant-detail" onClick={this.handleMerchantClick}>
          {logo ? (
            <img className="logo" src={logo} alt="merchant logo" />
          ) : (
            <MerchantLogoPlaceholder merchant={merchant ? merchant : {}} />
          )}
          <h2 className="name">{merchant ? merchant.Name : ''}</h2>
        </div>
        <div className="commission-rates">
          {commissionRates ? (
            commissionRates.length === 0 ? (
              <div className="no-rates">
                No earning rate details available. Please contact{' '}
                <span
                  className="email-support"
                  onClick={this.handleEmailSupport}
                >
                  support@wildlink.me
                </span>{' '}
                for more info.
              </div>
            ) : (
              commissionRates.map((rate) => (
                <CommissionRate key={rate.name} rate={rate} />
              ))
            )
          ) : null}
        </div>
      </div>
    );
  }
}

StoreDetail.propTypes = {
  commissionRates: PropTypes.array,
  merchant: PropTypes.shape({
    ID: PropTypes.number,
    Name: PropTypes.string,
    Images: PropTypes.array,
    ShortURL: PropTypes.string,
  }),
  getMerchant: PropTypes.func.isRequired,
  getMerchantCommissionRates: PropTypes.func.isRequired,
};

export default StoreDetail;
