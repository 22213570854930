import { R } from '../constants';

export const defaultClickHistory = {
  clickHistory: {},
  shortUrlList: [],
  shortURLSummary: 0,
};

const reducer = (state = defaultClickHistory, action) => {
  switch (action.type) {
    case R.SET_CLICK_HISTORY:
      return {
        ...state,
        clickHistory: action.clickHistory,
      };
    case R.SET_SHORT_URL_LIST:
      return {
        ...state,
        shortUrlList: action.shortUrlList,
      };
    case R.SET_SHORT_URL_SUMMARY:
      return {
        ...state,
        shortURLSummary: action.shortURLSummary,
      };
    default:
      return state;
  }
};

export default reducer;
