import React from 'react';

import './Visa.css';
import logo from './assets/images/visa-icon.svg';
import step1 from './assets/images/step1.png';
import step2 from './assets/images/step2.png';
import step3 from './assets/images/step3.png';
import step4 from './assets/images/step4.png';
import step5 from './assets/images/step5.png';
import step6 from './assets/images/step6.png';

const steps = [
  {
    number: 1,
    image: step1,
    description: 'Select the menu icon in the menu bar.',
  },
  {
    number: 2,
    image: step2,
    description: 'From the menu, tap Manage Extensions.',
  },
  {
    number: 3,
    image: step3,
    description: 'Under Extensions, find Visa & toggle it to ON.',
  },
  {
    number: 4,
    image: step4,
    description: 'On the next screen, tap Visa.',
  },
  {
    number: 5,
    image: step5,
    description: 'When prompted, tap Alway Allow.',
  },
  {
    number: 6,
    image: step6,
    description:
      'Finally, tap Always Allow on Every Website, so you never miss a chance to earn cash back.',
  },
];

const Step = ({ number, image, description }) => (
  <div className="step-visa">
    <div className="step-title-visa">
      {number && <div className="step-number-visa">{number}</div>}
      <div className="step-description-visa">{description}</div>
    </div>
    <div className="step-image-container-visa">
      <img src={image} className="step-image-visa" />
    </div>
  </div>
);

const Visa = () => {
  return (
    <main id="container-visa">
      <div className="heading-visa">
        <div className="image-wrapper-visa">
          <img src={logo} className="check-mark" />
        </div>
      </div>
      <div class="white-line"></div>
      <div class="yellow-line"></div>
      <h1 className="visa">5 Easy Steps to Install</h1>
      <div className="steps-visa">
        {steps.map((data, i) => (
          <Step key={i} {...data} />
        ))}
      </div>
      <div
        className="step-visa done-button"
        onClick={() => (window.location.href = 'https://www.visa.com')}
      >
        I'm done
      </div>
    </main>
  );
};

export default Visa;
