import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ErrorBoundary from '../../components/Error/ErrorBoundary/ErrorBoundary';
import MerchantListContainer from '../../components/Merchants/MerchantList/MerchantListContainer';
import SearchMerchantsContainer from '../../components/Merchants/SearchMerchants/SearchMerchantsContainer';
import { setTitle } from '../../helpers';
import './Stores.scss';
import StoresTutorial from './StoresTutorial';
import Modal from '../../components/Modal/Modal';

class Stores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didUserActivateAccessibility: false,
      isModalShowing: false,
    };
    this.setUserReturn = this.setUserReturn.bind(this);
    this.toggleUserModal = this.toggleUserModal.bind(this);
    this.userDismissedModal = this.userDismissedModal.bind(this);
  }

  componentDidMount() {
    setTitle('Stores');
    window.addEventListener('enabledAccessibility', this.setUserReturn);
    this.toggleUserModal(this.props.shouldUserSeeNewFeatureModal);
  }

  componentWillUnmount() {
    window.removeEventListener('enabledAccessibility', this.setUserReturn);
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      setMerchantSearchQuery,
      clearSearchedMerchants,
    } = this.props;

    if (location.key !== prevProps.location.key && !location.search) {
      setMerchantSearchQuery('');
      clearSearchedMerchants();
    }
  }

  setUserReturn() {
    this.setState({
      didUserActivateAccessibility: true,
      isModalShowing: true,
    });
  }

  toggleUserModal(isModalShowing) {
    this.setState({
      isModalShowing,
    });
  }

  userDismissedModal() {
    this.props.forceUserSeeNewFeatureModal(false);
  }

  render() {
    const { merchantCategories, location, history } = this.props;
    const hasSeenTutorial = window.localStorage.getItem('storesTutorial');

    const { isModalShowing, didUserActivateAccessibility } = this.state;

    const isAccessibilityEnabled = window.WF.isAccessibilityEnabled
      ? window.WF.isAccessibilityEnabled()
      : true;
    return (
      <div className="stores">
        {/* dont show tutorial if query string params (prefill search) */}
        {!hasSeenTutorial &&
          !location.search &&
          window.WF.getEnvironment() === 'web' && (
            <ErrorBoundary>
              <StoresTutorial canStart={merchantCategories.length > 0} />
            </ErrorBoundary>
          )}
        {window.WF.getEnvironment() !== 'web' &&
          !isAccessibilityEnabled &&
          isModalShowing && (
            <Modal
              btnText="Let's Go!"
              firstModal={true}
              toggleUserModal={this.toggleUserModal}
              isModalShowing={isModalShowing}
              userDismissedModal={this.userDismissedModal}
            >
              <h1>1-Tap Cashback 🎉</h1>
              <p>
                Wildlink has 1-Tap Cashback when browsing stores in Chrome.
                <br />
                <br />
                We use Android's accessibility feature to notify you when you're
                on an eligible website. Your browsing history stays on your
                device and it's not used for any other purpose.
                <br />
                <br />
                Sound good? Tap "Let's Go!" and then select Wildlink Cashback
                Notifications and enable the toggle on the next page.
              </p>
            </Modal>
          )}
        {didUserActivateAccessibility && isModalShowing && (
          <Modal
            btnText="See Example"
            firstModal={false}
            toggleUserModal={this.toggleUserModal}
            isModalShowing={isModalShowing}
          >
            <h1>All Set</h1>
            <p>
              Now you'll get a notification anytime you land on an eligible
              store. One tap activates cashback! Want to see an example?
            </p>
          </Modal>
        )}
        <SearchMerchantsContainer location={location} history={history} />
        <MerchantListContainer />
      </div>
    );
  }
}

Stores.propTypes = {
  setMerchantSearchQuery: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  merchantCategories: PropTypes.array.isRequired,
  clearSearchedMerchants: PropTypes.func.isRequired,
};

export default Stores;
