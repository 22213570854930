import { R } from '../constants';

export const defaultUser = () => ({
  uuid: localStorage.getItem('uuid'),
  deviceKey: localStorage.getItem('deviceKey'),
  deviceToken: window.WF.getDeviceToken(),
  deviceID: localStorage.getItem('deviceID'),
  senderToken: localStorage.getItem('senderToken'),
  senderId: Number(localStorage.getItem('senderId') || 0),
  phoneNumber: localStorage.getItem('phoneNumber'),
  detail: {},
  loginHasShown: false,
  paypal: {},
  isLoading: true,
  error: null,
  shouldUserSeeNewFeatureModal: false,
});

const reducer = (state = defaultUser(), action) => {
  switch (action.type) {
    case R.SET_UUID:
      return {
        ...state,
        uuid: action.uuid,
      };
    case R.SET_PHONE:
      return {
        ...state,
        phoneNumber: action.phone,
      };
    case R.SET_SENDER_TOKEN:
      return {
        ...state,
        senderToken: action.senderToken,
        senderId: action.senderId,
      };
    case R.SET_DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.deviceToken,
      };
    case R.SET_DEVICE_ID:
      return {
        ...state,
        deviceID: action.deviceID,
      };
    case R.SET_USER_DETAIL:
      return {
        ...state,
        detail: { ...action.userDetail },
      };
    case R.LOGIN_HAS_SHOWN:
      return {
        ...state,
        loginHasShown: true,
      };
    case R.SET_USER_PAYPAL:
      return {
        ...state,
        paypal: { ...action.userPaypal },
      };
    case R.USER_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case R.GET_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case R.GET_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case R.SET_USER_SEE_NEW_FEATURE_MODAL: {
      return {
        ...state,
        shouldUserSeeNewFeatureModal: action.shouldUserSeeNewFeatureModal,
      };
    }
    default:
      return state;
  }
};

export default reducer;
