import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isIOS, isAndroid } from '../../helpers';

import { Tooltip } from 'react-tippy';
import Facebook from './Facebook';
import Twitter from './Twitter';
import Email from './Email';
import SMS from './SMS';
import Copy from './Copy';
import ShareSheet from './ShareSheet';

import './Share.css';

class Share extends Component {
  state = {
    open: false,
    vanity: this.props.vanity || null,
    vanityIsLoading: false,
  };

  shareRef = React.createRef();

  toggleShareClick = async () => {
    let { open } = this.state;
    const {
      generateVanity,
      shortCode,
      vanity,
      isSearchResult,
      merchantName,
      shortURL,
    } = this.props;

    // make vanity when opening and when no vanity passed
    if (!open && !vanity) {
      this.setState({ vanityIsLoading: true });

      if (isSearchResult) {
        window.gtag('event', 'search result share', {
          event_category: 'search',
          event_label: merchantName || vanity,
        });
      }

      try {
        const vanity = await generateVanity(shortCode);
        if (vanity.vanityUrl) {
          this.setState({
            vanity: vanity.vanityUrl,
            vanityIsLoading: false,
          });
        } else {
          this.setState({
            vanity: shortURL,
            vanityIsLoading: false,
          });
        }
      } catch (error) {
        this.setState({
          vanity: shortURL,
          vanityIsLoading: false,
        });
      }
    }

    this.setState({ open: !open });
  };

  handleShareOptionClick = (e) => {
    const { merchantName, vanity } = this.props;
    window.gtag('event', 'stores', {
      event_category: 'Stores',
      event_label: `share ${e.target.alt}`,
      event_action: merchantName || vanity,
    });
  };

  closeShareOptions = () => {
    this.setState({ open: false });
  };

  handleShareOptionsShown = () => {
    window.addEventListener('scroll', this.closeShareOptions);
    // not supported by all browsers and sometimes not in .merchant-list context
    try {
      const merchantList = this.shareRef.current.closest('.merchant-list');
      merchantList.addEventListener('scroll', this.closeShareOptions);
    } catch (err) {}
  };

  handleShareOptionsHidden = () => {
    window.removeEventListener('scroll', this.closeShareOptions);
    try {
      const merchantList = this.shareRef.current.closest('.merchant-list');
      merchantList.removeEventListener('scroll', this.closeShareOptions);
    } catch (err) {}
  };

  render() {
    const { open, vanity, vanityIsLoading } = this.state;
    const environment = window.WF.getEnvironment();
    // mac but not ios mobile app
    const canShareSMS = isAndroid() || (isIOS() && environment !== 'ios');
    const canShareSheet = environment === 'android';
    const canShareEmail = environment !== 'ios';

    const shareOptionsClass = classNames('share-options', {
      loading: vanityIsLoading,
    });

    return (
      <Tooltip
        open={open}
        arrow
        interactive
        position="top"
        trigger="click"
        onRequestClose={this.closeShareOptions}
        onShown={this.handleShareOptionsShown}
        onHidden={this.handleShareOptionsHidden}
        html={
          <div className="share" onClick={this.handleShareOptionClick}>
            <Facebook vanity={vanity} />
            <Twitter vanity={vanity} />
            {canShareEmail ? <Email vanity={vanity} /> : null}
            {canShareSMS ? <SMS vanity={vanity} /> : null}
            <Copy vanity={vanity} />
            {canShareSheet ? <ShareSheet vanity={vanity} /> : null}
          </div>
        }
      >
        <div
          className={shareOptionsClass}
          onClick={this.toggleShareClick}
          ref={this.shareRef}
        >
          Share
        </div>
      </Tooltip>
    );
  }
}

Share.propTypes = {
  merchantName: PropTypes.string,
  vanity: PropTypes.string,
  generateVanity: PropTypes.func,
  shortCode: PropTypes.string,
  isSearchResult: PropTypes.bool,
  shortURL: PropTypes.string,
};

export default Share;
