import React from 'react';
import { Link } from 'react-router-dom';

import { isMobileApp } from '../../helpers';

import { URLS } from '../../constants';

import './Footer.scss';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  if (isMobileApp()) {
    return null;
  } else {
    return (
      <div className="footer">
        <footer>
          <a href={URLS.TERMS_PAGE}>Terms of Use</a>
          <a href={URLS.PRIVACY_PAGE}>Privacy</a>
          <a href={URLS.SUPPORT_PAGE}>Support</a>
          <a href={URLS.CONTACT_PAGE}>Contact</a>
        </footer>
        <div className="legal">
          <div>© Copyright {year} Wildfire Systems, Inc.</div>
          <div>All rights reserved.</div>
          <div>Patents Pending. Wildlink® Wildfire®</div>
          <div className="patents-link">
            <Link to="/patents">Pat. www.wildlink.me/patents</Link>
          </div>
        </div>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/4842543.js"
        ></script>
      </div>
    );
  }
};

export default Footer;
