import React, { Component } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isMobileApp } from '../../../helpers';

import Loading from '../../Loading/Loading';

import { faCheck } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class UserProfile extends Component {
  state = {
    email: this.props.userDetail.email,
    verificationError: undefined,
    verificationEmailSent: false,
    verificationEmailIsSending: false,
    isUpdatingEmail: false,
    error: false,
  };

  emailInput = React.createRef();

  handleEmailChange = () => {
    const email = this.emailInput.current.value;
    this.setState({ email });
  };

  handleEmailSave = async () => {
    const { userDetail, setUserDetail, updateUserDetail } = this.props;
    const updatedEmail = this.emailInput.current.value;
    // only do work if different email
    if (updatedEmail !== userDetail.email) {
      this.setState({
        isUpdatingEmail: true,
        error: false,
      });
      // update existing user detail object
      userDetail.email = updatedEmail;
      try {
        const response = await updateUserDetail(userDetail);
        if (response.status === 'success') {
          this.setState({ isUpdatingEmail: false });
          if (updatedEmail !== '') {
            this.handleSendVerificationEmail();
          }
          userDetail.email_validated = false;
          setUserDetail(userDetail);

          window.gtag('event', 'account_update', {
            event_label: 'Updated email',
          });
        } else {
          this.setState({
            error: true,
            isUpdatingEmail: false,
          });
        }
      } catch (error) {
        this.setState({
          error: true,
          isUpdatingEmail: false,
        });
      }
    } else {
      // clear error if user changes back to initial email
      this.setState({
        error: false,
      });
    }
  };

  handleSendVerificationEmail = async () => {
    const { sendVerificationEmail } = this.props;
    this.setState({
      verificationError: false,
      verificationEmailIsSending: true,
    });
    try {
      const response = await sendVerificationEmail();
      if (response.status === 'success') {
        this.setState({
          verificationError: false,
          verificationEmailIsSending: false,
        });
        this.verificationEmailHasSent();
      } else {
        this.setState({
          verificationError: true,
          verificationEmailIsSending: false,
        });
      }
    } catch (error) {
      this.setState({
        verificationError: true,
        verificationEmailIsSending: false,
      });
    }
  };

  verificationEmailHasSent = () => {
    this.setState({
      verificationEmailSent: true,
    });
    // make sure user is still on /settings
    window.setTimeout(() => {
      if (window.location.pathname === '/settings') {
        this.setState({ verificationEmailSent: false });
      }
    }, 3000);
  };

  handleBlur = (e) => {
    e.preventDefault();
    this.emailInput.current.blur();
    const isValid = e.target.checkValidity();
    if (isValid) {
      this.handleEmailSave();
    } else {
      this.setState({ error: true });
    }
  };

  // put cursor at the end on focus
  handleInputFocus = (e) => {
    const input = this.emailInput.current;
    const { value } = input;
    input.value = '';
    setTimeout(() => (input.value = value), 0);
  };

  render() {
    const { userDetail, userPaypal } = this.props;
    const {
      email,
      verificationError,
      isUpdatingEmail,
      error,
      verificationEmailIsSending,
      verificationEmailSent,
    } = this.state;
    if (!userDetail.phone_number && userPaypal?.receiver) return null;
    return (
      <div className="account">
        <h3>Account</h3>
        {!!userDetail.phone_number && !userPaypal?.receiver && (
          <div className="deprecated-disclaimer">
            <div>Logging in with your phone number is going away.</div>
            <div>
              <strong>
                You MUST connect your PayPal account by Apr 2, 2022
              </strong>
            </div>
            <div>to avoid losing access to your account.</div>
          </div>
        )}
        <div className="entry login-info">
          <label>Login</label>
          <span className="phone">
            {userDetail.phone_number || 'Logged in with PayPal'}
          </span>
        </div>
        <form className="entry" onSubmit={this.handleBlur}>
          <label>Email</label>
          {userDetail.email_validated && !error ? (
            <FontAwesomeIcon className="check" icon={faCheck} size="sm" />
          ) : null}
          {isUpdatingEmail ? (
            <Loading size="2x" color="grey" />
          ) : (
            <input
              ref={this.emailInput}
              type="email"
              className={
                error ? 'error' : userDetail.email_validated ? 'verified' : null
              }
              value={email}
              placeholder="your@email.com"
              onChange={this.handleEmailChange}
              onBlur={this.handleBlur}
              onFocus={this.handleInputFocus}
              size={10}
            />
          )}
        </form>
        <div className="detail">
          {verificationError
            ? 'We were unable to send a verification email.'
            : 'Your email is used to recover your account.'}
          {userDetail.email_validated ? null : verificationEmailSent ? (
            <div>Verification email sent!</div>
          ) : (
            userDetail.email !== '' && (
              <div
                className="resend link"
                onClick={this.handleSendVerificationEmail}
              >
                Resend verification email{' '}
                {verificationEmailIsSending ? <Loading size="1x" /> : null}
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

UserProfile.propTypes = {
  history: PropTypes.object.isRequired,
  userLogout: PropTypes.func.isRequired,
  userDetail: PropTypes.object.isRequired,
  updateUserDetail: PropTypes.func.isRequired,
  sendVerificationEmail: PropTypes.func.isRequired,
  setUserDetail: PropTypes.func.isRequired,
};

export default withRouter(UserProfile);
