import React, { Component } from 'react';

import NetworkError from '../Error/NetworkError/NetworkError';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
        error: null,
      };
    }

    setComponent = async () => {
      try {
        const { default: component } = await importComponent();
        this.setState({
          component,
        });
      } catch (error) {
        this.setState({ error });
      }
    };

    retrySetComponent = async () => {
      this.setState({ error: null });
      await this.setComponent();
    };

    async componentDidMount() {
      await this.setComponent();
    }

    render() {
      const { component: Component, error } = this.state;

      if (error) {
        return (
          <NetworkError
            error={error.message}
            onRetry={this.retrySetComponent}
          />
        );
      }

      return Component ? <Component {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
