import React from 'react';
import PropTypes from 'prop-types';

import TwitterIcon from '../../static/images/share/twitter.png';

const Twitter = ({ vanity }) => {
  const hashTags = 'wildlink';
  const twitterShare = `https://twitter.com/intent/tweet?url=${vanity}&hashtags=${hashTags}`;
  const handleClick = () => window.WF.openUrl(twitterShare);

  return (
    <img
      className="icon"
      src={TwitterIcon}
      alt="twitter"
      onClick={handleClick}
    />
  );
};

Twitter.propTypes = {
  vanity: PropTypes.string,
};

export default Twitter;
