import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isMobileApp, setTitle } from '../../helpers/index';

import Loading from '../../components/Loading/Loading';
import MobileDebug from '../../components/MobileDebug/MobileDebug';
import UserProfileContainer from '../../components/Settings/UserProfile/UserProfileContainer';
import PaymentPreferences from '../../components/Settings/PaymentPreferences/PaymentPreferences';
import NetworkError from '../../components/Error/NetworkError/NetworkError';
import TermsAndPrivacy from '../../components/MobileApp/TermsAndPrivacy/TermsAndPrivacy';

import './Settings.css';

class Settings extends Component {
  handlePatentClick = () =>
    window.WF.openUrl('https://www.wildlink.me/patents');

  componentDidMount() {
    setTitle('Settings');
    const { getUser } = this.props;
    const { isMobileDebug } = this.props.location.state || false;
    if (!isMobileDebug) {
      getUser();
    }
  }
  handleLogout = () => {
    const { history, userLogout } = this.props;
    userLogout();
    history.push('/settings');
  };

  render() {
    const { isLoading, error, getUser, deviceID } = this.props;
    const { isMobileDebug } = this.props.location.state || false;

    if (isMobileDebug) {
      return <MobileDebug />;
    }

    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return <NetworkError onRetry={getUser} error={error} />;
    }

    return (
      <div className="settings">
        <UserProfileContainer />
        <PaymentPreferences deviceID={deviceID} />
        {isMobileApp() && (
          <div className="terms-privacy-patent">
            <TermsAndPrivacy />
            <div className="patent">
              Pat.{' '}
              <span onClick={this.handlePatentClick}>
                www.wildlink.me/patents
              </span>
            </div>
          </div>
        )}
        {!isMobileApp() && (
          <div className="link mt" onClick={this.handleLogout}>
            Logout
          </div>
        )}
      </div>
    );
  }
}

Settings.propTypes = {
  getUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default Settings;
