import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isMobileApp } from '../../../helpers';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MerchantCardContainer from '../MerchantCard/MerchantCardContainer';

import './MerchantCategory.css';

class MerchantCategory extends Component {
  state = {
    isHovering: false,
    maxLeft: true,
    maxRight: false,
    // remove hover from touch devices
    canTouch: 'ontouchstart' in document.documentElement,
  };

  merchantCategoryContainer = React.createRef();

  handleMouseEnter = () => {
    if (!this.state.canTouch) {
      this.setState({ isHovering: true });
    }
  };

  handleMouseLeave = () => {
    if (!this.state.canTouch) {
      this.setState({ isHovering: false });
    }
  };

  getNavDistance = () => {
    const container = this.merchantCategoryContainer.current;
    const cardWidth = container.firstChild.getBoundingClientRect().width;
    const cardsPerView = Math.floor(container.clientWidth / cardWidth);
    const distance = cardsPerView * cardWidth;
    return Math.ceil(distance);
  };

  handleNavRight = () => {
    const container = this.merchantCategoryContainer.current;
    container.scrollLeft = container.scrollLeft + this.getNavDistance();
  };

  handleNavLeft = () => {
    const container = this.merchantCategoryContainer.current;
    container.scrollLeft = container.scrollLeft - this.getNavDistance();
  };

  handleScroll = () => {
    const { scrollLeft } = this.merchantCategoryContainer.current;
    // padding buffer
    if (scrollLeft + 816 > this.merchantCategoryContainer.current.scrollWidth) {
      this.setState({ maxRight: true });
    } else {
      this.setState({ maxRight: false });
    }
    if (scrollLeft < 16) {
      this.setState({ maxLeft: true });
    } else {
      this.setState({ maxLeft: false });
    }
  };

  render() {
    const { category } = this.props;
    const { isHovering, maxLeft, maxRight } = this.state;

    const leftChevronClass = classNames('left-chevron', {
      hidden: maxLeft,
    });
    const rightChevronClass = classNames('right-chevron', {
      hidden: maxRight,
    });
    const horizontalScrollNavClass = classNames('horizontal-scroll-nav', {
      hidden: !isHovering || isMobileApp(),
    });

    return (
      <div className="merchant-category">
        <h4>{category.name}</h4>
        <div
          className="merchant-list horizontal-scroll"
          ref={this.merchantCategoryContainer}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onScroll={this.handleScroll}
        >
          {category.merchants.map((merchantId) => (
            <MerchantCardContainer key={merchantId} merchantId={merchantId} />
          ))}
          <div className={horizontalScrollNavClass}>
            <div className={leftChevronClass} onClick={this.handleNavLeft}>
              <FontAwesomeIcon icon={faChevronLeft} size="1x" />
            </div>
            <div className={rightChevronClass} onClick={this.handleNavRight}>
              <FontAwesomeIcon icon={faChevronRight} size="1x" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MerchantCategory.propTypes = {
  category: PropTypes.object.isRequired,
};

export default MerchantCategory;
