import { connect } from 'react-redux';

import {
  userIsLoadingSelector,
  userErrorSelector,
  deviceIDSelector,
} from '../../selectors/user';

import { getUser, userLogout } from '../../actions';

import Settings from './Settings';

const mapStateToProps = (state) => ({
  deviceID: deviceIDSelector(state),
  isLoading: userIsLoadingSelector(state),
  error: userErrorSelector(state),
});

const mapActionsToProps = {
  getUser,
  userLogout,
};

export default connect(mapStateToProps, mapActionsToProps)(Settings);
