import { connect } from 'react-redux';

import {
  senderTokenSelector,
  phoneNumberSelector,
  deviceTokenSelector,
} from '../../../selectors';

import {
  setSenderToken,
  verifyCode,
  doesUserHavePaypal,
  userLogout,
} from '../../../actions';

import Verify from './Verify';

const mapStateToProps = (state) => ({
  phoneNumber: phoneNumberSelector(state),
  senderToken: senderTokenSelector(state),
  deviceToken: deviceTokenSelector(state),
});

const mapActionsToProps = {
  setSenderToken,
  verifyCode,
  doesUserHavePaypal,
  userLogout,
};

export default connect(mapStateToProps, mapActionsToProps)(Verify);
