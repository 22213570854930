import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { loadScript, setTitle, isMobileApp } from '../../helpers';

import { faTimes } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Support.css';

class Support extends Component {
  removeReamazeWidget = () => {
    try {
      document.getElementById('reamaze-widget').remove();
    } catch (error) {}
  };

  componentDidMount() {
    setTitle('Support');
    // set widget config here so it does not persist if contact is clicked first
    if (typeof window._support['ui']['widget'] === 'undefined') {
      window._support['ui']['widget'] = {
        icon: 'chat',
        label: {
          text: 'Let us know if you have any questions! 😊',
          mode: 'notification',
          delay: 2,
          duration: 3,
        },
      };
    }
    if (typeof window.reamaze === 'undefined') {
      loadScript('https://cdn.reamaze.com/assets/reamaze.js');
    } else {
      window.reamaze.reload();
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.key !== prevProps.location.key && location.hash === '') {
      window.reamaze.reload();
    }
  }

  componentWillUnmount() {
    // remove reamaze container or else the button persists
    const reamazeWidget = document.getElementById('reamaze-widget');
    if (reamazeWidget) {
      reamazeWidget.remove();
    } else {
      setTimeout(this.removeReamazeWidget, 3000);
    }
  }

  render() {
    const { state } = this.props.location;
    const hasPath = state && state.topic && state.article;

    return (
      <div className="support">
        {isMobileApp() ? (
          <Link
            className="close"
            to={{
              pathname: '/earnings',
            }}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </Link>
        ) : null}
        <div
          data-reamaze-embed="kb"
          data-reamaze-embed-path={
            hasPath ? `/kb/${state.topic}/${state.article}` : ''
          }
        />
      </div>
    );
  }
}

Support.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      topic: PropTypes.string,
      article: PropTypes.string,
    }),
  }),
};

export default Support;
