import { connect } from 'react-redux';

import { loginHasShownSelector, senderTokenSelector } from '../../../selectors';

import RequestLoginRoute from './RequestLoginRoute';

const mapStateToProps = (state) => ({
  senderToken: senderTokenSelector(state),
  loginHasShown: loginHasShownSelector(state),
});

export default connect(mapStateToProps, null)(RequestLoginRoute);
