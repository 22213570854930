import React from 'react';
import PropTypes from 'prop-types';

import './CommissionRate.scss';

const CommissionRate = ({ rate }) => {
  return (
    <div className="commission-rate">
      <div className="left">{rate.name}</div>
      <div className="right">{rate.parsedRate}</div>
    </div>
  );
};

CommissionRate.propTypes = {
  rate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    parsedRate: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
  }),
};

export default CommissionRate;
