import React from 'react';
import PropTypes from 'prop-types';

import shareMore from '../../static/images/icons/share-more-icon.png';

const ShareSheet = ({ vanity }) => {
  const openShareSheet = () => {
    window.WF.openShareSheet(vanity);
  };

  return (
    <img
      className="icon"
      src={shareMore}
      alt="sharesheet"
      onClick={openShareSheet}
    />
  );
};

ShareSheet.propTypes = {
  vanity: PropTypes.string,
};

export default ShareSheet;
