import { R } from '../constants';

export const defaultMerchants = {
  merchantCategories: [],
  merchantsById: {},
  commissionRatesByMerchantId: {},
  allCategoryMerchantsSuccess: false,
  allCategoryMerchantsError: null,
  searchMerchantsIsLoading: false,
  searchMerchantsError: null,
  searchedMerchants: [],
  searchMerchantsQuery: '',
  noSearchMerchantsResults: false,
  listMerchants: false,
  bannerMerchantsIsLoading: false,
  bannerMerchants: [],
  activeDomainsMerchants: {},
};

const reducer = (state = defaultMerchants, action) => {
  switch (action.type) {
    case R.GET_ALL_CATEGORY_MERCHANTS_IS_LOADING:
      return {
        ...state,
        allCategoryMerchantsSuccess: false,
        allCategoryMerchantsError: null,
      };
    case R.SET_MERCHANT_CATEGORIES:
      return {
        ...state,
        merchantCategories: action.payload.merchantCategories,
      };
    case R.SET_MERCHANTS_BY_ID:
      return {
        ...state,
        merchantsById: {
          ...state.merchantsById,
          ...action.payload.merchantsById,
        },
      };
    case R.SET_ACTIVE_DOMAINS_MERCHANTS:
      return {
        ...state,
        activeDomainsMerchants: { ...action.payload.activeDomainsMerchants },
      };
    case R.GET_ALL_CATEGORY_MERCHANTS_SUCCESS:
      return {
        ...state,
        allCategoryMerchantsSuccess: true,
        allCategoryMerchantsError: null,
      };
    case R.GET_ALL_CATEGORY_MERCHANTS_FAILURE:
      return {
        ...state,
        allCategoryMerchantsSuccess: false,
        allCategoryMerchantsError: action.payload.errorMessage,
      };
    case R.SET_MERCHANT_COMMISSION_RATES:
      return {
        ...state,
        commissionRatesByMerchantId: {
          ...state.commissionRatesByMerchantId,
          ...action.payload.commissionRates,
        },
      };
    case R.SEARCH_MERCHANTS_IS_LOADING:
      return {
        ...state,
        searchMerchantsIsLoading: true,
        searchMerchantsError: null,
      };
    case R.SEARCH_MERCHANTS_SUCCESS:
      return {
        ...state,
        searchMerchantsIsLoading: false,
        noSearchMerchantsResults: false,
        searchedMerchants: action.merchants,
      };
    case R.SEARCH_MERCHANTS_FAILURE:
      return {
        ...state,
        searchMerchantsIsLoading: false,
        searchMerchantsError: action.error,
        searchedMerchants: [],
      };
    case R.NO_MERCHANT_SEARCH_RESULTS:
      return {
        ...state,
        noSearchMerchantsResults: true,
        searchMerchantsIsLoading: false,
      };
    case R.CLEAR_SEARCHED_MERCHANTS:
      return {
        ...state,
        searchedMerchants: [],
        noSearchMerchantsResults: false,
      };
    case R.SET_MERCHANT_SEARCH_QUERY:
      return {
        ...state,
        searchMerchantsQuery: action.searchQuery,
      };
    case R.UPDATE_LIST_MERCHANTS:
      return {
        ...state,
        listMerchants: action.showMerchantList,
      };
    case R.BANNER_MERCHANTS_IS_LOADING:
      return {
        ...state,
        bannerMerchantsIsLoading: true,
      };
    case R.SET_BANNER_MERCHANTS:
      return {
        ...state,
        bannerMerchants: action.bannerMerchants,
        bannerMerchantsIsLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
