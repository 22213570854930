import { connect } from 'react-redux';

import { verifyEmail } from '../../../actions';

import ValidateEmail from './ValidateEmail';

const mapActionsToProps = {
  verifyEmail,
};

export default connect(null, mapActionsToProps)(ValidateEmail);
