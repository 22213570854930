import store, { history } from '../../store';
import { GTAG_ID } from '../../constants';

const state = store.getState();
const userDetail = state.user.detail;

window.dataLayer = window.dataLayer || [];

const gtag = function() {
  window.dataLayer.push(arguments);
};

gtag('js', new Date());
gtag('config', GTAG_ID, {
  custom_map: {
    dimension1: 'email_is_set',
    dimension2: 'payment_type_is_set',
  },
  send_page_view: true,
});

if (userDetail.email) {
  gtag('event', 'email_is_set_dimension', {
    email_is_set: 1,
    non_interaction: true,
  });
} else {
  gtag('event', 'email_is_set_dimension', {
    email_is_set: 0,
    non_interaction: true,
  });
}

if (userDetail.payment_type) {
  gtag('event', 'payment_type_is_set_dimension', {
    payment_type_is_set: 1,
    non_interaction: true,
  });
} else {
  gtag('event', 'payment_type_is_set_dimension', {
    payment_type_is_set: 0,
    non_interaction: true,
  });
}

history.listen((location) => {
  gtag('config', GTAG_ID, { page_path: location.pathname });
});

window.gtag = gtag;
