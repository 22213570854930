import PropTypes from 'prop-types';
import React from 'react';
import NetworkError from '../../../Error/NetworkError/NetworkError';
import Loading from '../../../Loading/Loading';
import MerchantListItemContainer from '../../MerchantListItem/MerchantListItemContainer';
import './SearchMerchantsResults.css';

const SearchMerchantsResults = ({
  searchedMerchants,
  noSearchResults,
  isLoading,
  error,
  searchQuery,
  searchMerchants,
}) => {
  const retrySearchMerchants = () => {
    searchMerchants(searchQuery);
  };

  if (error) {
    return <NetworkError onRetry={retrySearchMerchants} error={error} />;
  }

  return (
    <div className="search-merchants-results">
      {isLoading && !searchedMerchants.length ? (
        <Loading />
      ) : noSearchResults ? (
        'No Search Results'
      ) : (
        searchedMerchants.map((merchant) => (
          <MerchantListItemContainer
            key={merchant.ID}
            merchant={merchant}
            isSearchResult
          />
        ))
      )}
    </div>
  );
};

SearchMerchantsResults.propTypes = {
  noSearchResults: PropTypes.bool.isRequired,
  searchedMerchants: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  searchQuery: PropTypes.string.isRequired,
  searchMerchants: PropTypes.func.isRequired,
};

export default SearchMerchantsResults;
