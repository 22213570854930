import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isMobileApp } from '../../helpers';

import copyIcon from '../../static/images/share/copy.png';
import copiedIcon from '../../static/images/share/copied.png';

class Copy extends Component {
  state = {
    hasCopied: false,
  };

  handleCopy = () => {
    const { vanity } = this.props;
    const success = window.WF.copyToClipboard(vanity);
    // copying in mobile web app guaranteed
    if (success || isMobileApp()) {
      this.setState({ hasCopied: true });
      window.setTimeout(this.revertCopyIcon, 2000);
    }
  };

  revertCopyIcon = () => {
    this.setState({ hasCopied: false });
  };

  render() {
    const { hasCopied } = this.state;
    return (
      <img
        className="icon"
        src={hasCopied ? copiedIcon : copyIcon}
        alt="copy"
        onClick={this.handleCopy}
      />
    );
  }
}

Copy.propTypes = {
  vanity: PropTypes.string,
};

export default Copy;
