import { isIOS } from '../../helpers';

const copyToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // make text area not visible
  textArea.setAttribute('readonly', '');
  textArea.style.position = 'absolute';
  textArea.style.left = '-9999px';
  document.body.appendChild(textArea);
  // check if there is any content selected previously and store it
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  // important! iOS handles copy differently and we check for iOS via user agent
  // chrome changes the user agent in device devtools so it will not work in chrome if emulating iOS!
  if (isIOS()) {
    textArea.contentEditable = true;
    const range = document.createRange();
    range.selectNodeContents(textArea);
    document.getSelection().addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }
  let success = false;
  try {
    // only works as a result of a user action (click events)
    success = document.execCommand('copy');
  } catch (error) {}
  document.body.removeChild(textArea);
  if (selected) {
    // unselect everything
    document.getSelection().removeAllRanges();
    // restore original selection
    document.getSelection().addRange(selected);
  }
  return success;
};

const getEnvironmentMetadata = () => {
  const metadata = {
    native_bridge_version: 'web_native_bridge_version',
    app_version: 'web_app_version',
    bundle_id: 'web_bundle_id',
    device_model: 'web_device_model',
    os: 'web_os',
    fcm_token: 'web_fcm_token',
    uuid: 'web_uuid',
  };
  return JSON.stringify(metadata);
};

const resetOnboardingFlags = () => {
  console.log('resetOnboardingFlags()');
};

const sendEmail = (subject, body, email = '') => {
  const url = `mailto:${email}?subject=${subject}&body=${body}`;
  return (window.location.href = url);
};

const sendSMS = (body) => {
  const url = `sms:?&body=${body}`;
  return (window.location.href = url);
};

const openShareSheet = (url) => {
  console.log(`openShareSheet('${url}')`);
};

if (typeof window.WF === 'undefined') {
  //changes to the WF object below may break android builds - please confirm with Uzair/Rudem before making changes
  window.WF = {
    getEnvironment: () => 'web',
    // need empty string because this is passed to api in auth header
    getDeviceToken: () => localStorage.getItem('deviceToken') || '',
    openUrl: (url) => window.open(url),
    copyToClipboard,
    getEnvironmentMetadata,
    resetOnboardingFlags,
    sendEmail,
    sendSMS,
    openShareSheet,
    showModal: () => false,
    navigateToSettings: () => {},
    isAccessibilityEnabled: () => true,
    showExample: (URL) => {},
    modalDismissed: () => {},
  };
}

window.enabledAccessibility = new Event('enabledAccessibility');
