import { connect } from 'react-redux';

import {
  commissionRatesSelector,
  merchantByIdSelector,
} from '../../../selectors';

import { getMerchantCommissionRates, getMerchant } from '../../../actions';

import StoreDetail from './StoreDetail';

const mapStateToProps = (state, props) => ({
  commissionRates: commissionRatesSelector(
    state,
    props.match.params.merchantId,
  ),
  merchant: merchantByIdSelector(state, props.match.params.merchantId),
});

const mapActionsToProps = {
  getMerchantCommissionRates,
  getMerchant,
};

export default connect(mapStateToProps, mapActionsToProps)(StoreDetail);
