import { connect } from 'react-redux';

import {
  userLogout,
  setUserDetail,
  updateUserDetail,
  sendVerificationEmail,
} from '../../../actions';
import { userPaypalSelector } from '../../../selectors';

import UserProfile from './UserProfile';

const mapStateToProps = (state) => ({
  userDetail: state.user.detail,
  userPaypal: userPaypalSelector(state),
});

const mapActionsToProps = {
  userLogout,
  setUserDetail,
  updateUserDetail,
  sendVerificationEmail,
};

export default connect(mapStateToProps, mapActionsToProps)(UserProfile);
