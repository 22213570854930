import { wfFetch } from '../actions';
import { R, URLS } from '../constants';
import crypto from 'crypto-js';
import { getUuid, isDevSite } from '../helpers';

export const statsIsLoading = () => ({ type: R.STATS_IS_LOADING });

export const getEarningsSummary = () => {
  return async (dispatch) => {
    try {
      const commissionSummary = await dispatch(
        wfFetch('GET', '/api/commission-summary'),
      );
      dispatch(setEarningsSummary(commissionSummary));
    } catch (error) {
      dispatch(getStatsFailure(error.message));
    }
  };
};

export const getStats = () => {
  return async (dispatch) => {
    dispatch(statsIsLoading());
    return Promise.all([
      dispatch(wfFetch('GET', '/api/shorturl')),
      dispatch(wfFetch('GET', '/api/shorturl-summary')),
      dispatch(getCloudProfile()),
    ])
      .then((data) => {
        dispatch(setStats(...data));
      })
      .catch((error) => {
        dispatch(getStatsFailure(error.message));
      });
  };
};

export const getStatsSuccess = () => ({ type: R.GET_STATS_SUCCESS });

export const getStatsFailure = (error) => ({
  type: R.GET_STATS_FAILURE,
  error,
});

export const setStats = (shortUrls, shortURLSummary) => {
  return (dispatch) => {
    return Promise.all([
      dispatch(setShortUrlList(shortUrls)),
      dispatch(setShortURLSummary(shortURLSummary)),
    ])
      .then(() => {
        dispatch(getStatsSuccess());
      })
      .catch((error) => {
        dispatch(getStatsFailure(error.message));
      });
  };
};

export const setShortUrlList = (shortUrlList) => ({
  type: R.SET_SHORT_URL_LIST,
  shortUrlList,
});

export const setClickHistory = (clickHistory) => ({
  type: R.SET_CLICK_HISTORY,
  clickHistory,
});

export const setEarningsDetail = (earningsDetail) => ({
  type: R.SET_EARNINGS_DETAIL,
  earningsDetail,
});

export const setEarningsSummary = (earningsSummary) => ({
  type: R.SET_EARNINGS_SUMMARY,
  earningsSummary,
});

export const setShortURLSummary = (shortURLSummary) => ({
  type: R.SET_SHORT_URL_SUMMARY,
  shortURLSummary,
});

export const setActivityFilter = (filter) => ({
  type: R.SET_ACTIVITY_FILTER,
  filter,
});

export const generateVanity = (shortCode) => {
  const placement = `webview-${window.WF.getEnvironment()}`;
  return (dispatch) => {
    return dispatch(wfFetch('POST', '/api/vanity', { shortCode, placement }));
  };
};

export const getCloudProfile = () => {
  return async (dispatch) => {
    try {
      const commissionUrl =
        URLS.COMMISSION_URL_BASE + (isDevSite() ? 'DEV' : '');
      const deviceKey = await getUuid();
      if (!deviceKey) throw new Error('Device not found');
      const token = crypto.SHA1(deviceKey).toString();
      const res = await fetch(`${commissionUrl}${token}.json`);
      if (res.ok) {
        const cloudProfile = await res.json();
        dispatch(setCloudProfile(cloudProfile));
      }
    } catch (error) {
      dispatch(getStatsFailure(error.message));
    }
  };
};

const setCloudProfile = (cloudProfile) => ({
  type: R.SET_CLOUD_PROFILE,
  cloudProfile,
});
