import {
  defaultClickHistory,
  defaultEarnings,
  defaultMerchants,
  defaultStats,
  defaultUser,
  defaultErrors,
} from './reducers';

const initialState = () => ({
  user: defaultUser(),
  clicks: defaultClickHistory,
  earnings: defaultEarnings,
  merchants: defaultMerchants,
  stats: defaultStats,
  errors: defaultErrors,
});

export default initialState;
