import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { extension } from '../../../helpers/chrome';

import Button from '../../../components/Button/Button';
import Notification from '../../../components/Notification/Notification';
import NetworkError from '../../../components/Error/NetworkError/NetworkError';

import logoGradient from '../../../static/images/logo-gradient.png';
import '../Login.css';

class Verify extends Component {
  state = {
    code: '',
    hasError: false,
    destination: '/settings',
    phoneNumber: localStorage.getItem('phoneNumber') || this.props.phoneNumber,
    hasNetworkError: false,
  };

  codeInput = React.createRef();

  handleInput = () => {
    const { hasError } = this.state;
    this.setState({ code: this.codeInput.current.value });
    if (hasError) {
      this.setState({ hasError: false });
    }
  };

  handleSubmit = async (e) => {
    window.gtag('event', 'login', {
      event_label: 'verify code attempted',
    });

    e.preventDefault();
    const {
      setSenderToken,
      history,
      verifyCode,
      doesUserHavePaypal,
      userLogout,
    } = this.props;
    const { destination, phoneNumber, code } = this.state;

    try {
      const response = await verifyCode(phoneNumber, code);

      if (response.status === 'success') {
        const { SenderToken, SenderID } = response;
        // set sender tokens
        setSenderToken(SenderToken, SenderID);
        localStorage.setItem('senderToken', SenderToken);
        localStorage.setItem('senderId', SenderID);
        const hasPaypal = await doesUserHavePaypal();
        if (hasPaypal) {
          userLogout();
          return history.replace('/login', {
            doesUserHavePaypalAlready: hasPaypal,
          });
        }
        if (
          (await extension.canRunExtension()) &&
          !(await extension.isExtensionLoginValid({ SenderToken, SenderID }))
        ) {
          await extension.clear();
          const wasSenderCreationSuccessful = await extension.makeSenderFromPaypalExtension(
            { SenderToken, SenderID },
          );
          if (!wasSenderCreationSuccessful) {
            throw new Error(
              'Could not create sender after second attempt and clearing extension',
            );
          }
        }

        history.push(destination);

        window.gtag('event', 'login', {
          event_label: 'verify code succeeded',
        });
      } else {
        this.setState({ hasError: true });

        window.gtag('event', 'login', {
          event_label: 'verify code failed',
        });
      }
    } catch (error) {
      userLogout();
      history.replace('/login', {
        doesUserHavePaypalAlready: false,
      });
      this.setState({ hasNetworkError: true });
    }
  };

  onNetworkErrorRetry = () => {
    this.setState({
      code: '',
      hasNetworkError: false,
    });
  };

  componentDidMount() {
    const { history, senderToken, location } = this.props;

    const requireLogin = get(location, 'state.requireLogin', false);
    if (senderToken && !requireLogin) {
      history.replace('/settings');
    } else {
      const from = get(location, 'state.from');
      if (from) {
        this.setState({ destination: from });
      }
    }

    this.codeInput.current.focus();
  }

  render() {
    const { hasError, code, phoneNumber, hasNetworkError } = this.state;

    if (hasNetworkError) {
      return <NetworkError onRetry={this.onNetworkErrorRetry} />;
    }

    return (
      <div className="login verify">
        <div className="upper">
          <img className="logo" src={logoGradient} alt="logo" />
        </div>
        <h3>Verification Code</h3>
        <div className="subheading">
          Enter the code we sent via text to continue.
        </div>
        <form onSubmit={this.handleSubmit}>
          <input
            type="tel"
            className="code"
            ref={this.codeInput}
            onInput={this.handleInput}
            placeholder="______"
          />
          <Button
            buttonRef={this.submitButton}
            size="small"
            disabled={code.length === 0 || !phoneNumber}
            onClick={this.handleSubmit}
            gradient
          >
            Submit
          </Button>
        </form>
        <div className="detail">
          Didn't get the code? <Link to="/login">Resend code</Link>
        </div>
        {hasError && (
          <Notification type="error">
            The verification code is invalid (it may have expired). Please check
            the code and try again (or go back and generate a new code).
          </Notification>
        )}
      </div>
    );
  }
}

Verify.propTypes = {
  history: PropTypes.object.isRequired,
  senderToken: PropTypes.string,
  setSenderToken: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  location: PropTypes.object.isRequired,
};

export default Verify;
