import { connect } from 'react-redux';

import { searchMerchantsQuerySelector } from '../../../selectors/merchants';

import MerchantList from './MerchantList';

const mapStateToProps = (state) => ({
  searchQuery: searchMerchantsQuerySelector(state),
});

export default connect(mapStateToProps, null)(MerchantList);
