import React from 'react';
import PropTypes from 'prop-types';

import SearchMerchantsResultsContainer from '../SearchMerchants/SearchMerchantsResults/SearchMerchantsResultsContainer';
import MerchantCategoriesContainer from '../MerchantCategories/MerchantCategoriesContainer';
import BannerCarouselContainer from '../../BannerCarousel/BannerCarouselContainer';

import './MerchantList.css';

const MerchantList = ({ searchQuery }) => {
  if (searchQuery.length > 2) {
    return <SearchMerchantsResultsContainer />;
  } else {
    return (
      <div className="merchant-list">
        <BannerCarouselContainer />
        <MerchantCategoriesContainer />
      </div>
    );
  }
};

MerchantList.propTypes = {
  searchQuery: PropTypes.string.isRequired,
};

export default MerchantList;
