import { connect } from 'react-redux';

import {
  noSearchMerchantsResultsSelector,
  searchedMerchantsSelector,
  searchMerchantsIsLoadingSelector,
  searchMerchantsErrorSelector,
  searchMerchantsQuerySelector,
} from '../../../../selectors/merchants';

import { searchMerchants, clearSearchedMerchants } from '../../../../actions';

import SearchMerchantsResults from './SearchMerchantsResults';

const mapStateToProps = (state) => ({
  noSearchResults: noSearchMerchantsResultsSelector(state),
  searchedMerchants: searchedMerchantsSelector(state),
  isLoading: searchMerchantsIsLoadingSelector(state),
  error: searchMerchantsErrorSelector(state),
  searchQuery: searchMerchantsQuerySelector(state),
});

const mapDispatchToProps = {
  searchMerchants,
  clearSearchedMerchants,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchMerchantsResults);
