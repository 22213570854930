import React from 'react';
import PropTypes from 'prop-types';

import { faExclamationTriangle } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../Button/Button';

import './NetworkError.css';

const NetworkError = ({ onRetry, error = 'Failed to fetch' }) => {
  window.gtag('event', 'network_error', {
    event_label: error,
  });

  return (
    <div className="network-error">
      <FontAwesomeIcon
        className="icon"
        icon={faExclamationTriangle}
        size="2x"
      />
      <div className="content">
        Oops! Something went wrong and we couldn't load the page. Please try
        again later.
      </div>
      <Button size="small" onClick={onRetry}>
        Try Again
      </Button>
    </div>
  );
};

NetworkError.propTypes = {
  onRetry: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default NetworkError;
