import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Loading from '../../../components/Loading/Loading';
import NetworkError from '../../../components/Error/NetworkError/NetworkError';

import './ValidateEmail.css';

class ValidateEmail extends Component {
  state = {
    isLoading: true,
    isValidated: false,
    error: null,
  };

  retryValidate = () => {
    this.setState({
      isLoading: true,
      isValidated: false,
      error: null,
    });
    this.validate();
  };

  validate = async () => {
    const { verifyEmail, match } = this.props;
    const { validationToken } = match.params;
    try {
      const response = await verifyEmail(validationToken);
      if (response.status === 'success') {
        this.setState({
          isValidated: true,
          isLoading: false,
        });
      } else {
        this.setState({
          isValidated: false,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  async componentDidMount() {
    await this.validate();
  }

  render() {
    const { isLoading, isValidated, error } = this.state;

    if (error) {
      return <NetworkError onRetry={this.retryValidate} error={error} />;
    }

    if (isLoading) {
      return <Loading />;
    }

    if (isValidated) {
      return (
        <div className="validate-email">
          <div>Thank you for verifying your email address.</div>
          <br />
          <div>
            <Link to="/settings">Continue to settings</Link>
          </div>
        </div>
      );
    }

    return (
      <div className="validate-email">
        <div>
          This verification link is incorrect or has expired. Please try{' '}
          <Link to="/settings">sending yourself a new verification link</Link>.
        </div>
        <br />
        <div>
          If you're still getting errors after sending a new link, please{' '}
          <Link to="/support">contact support</Link>.
        </div>
      </div>
    );
  }
}

export default ValidateEmail;
