import { connect } from 'react-redux';

import { generateVanity } from '../../actions';

import Share from './Share';

const mapActionsToProps = {
  generateVanity,
};

export default connect(null, mapActionsToProps)(Share);
