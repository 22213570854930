import { connect } from 'react-redux';

import {
  deviceTokenSelector,
  badAuthSelector,
  deviceIDSelector,
  deviceKeySelector,
  uuidSelector,
} from '../../selectors';

import { getDeviceToken, setUserSeeNewFeatureModal } from '../../actions';

import Main from './Main';

const mapStateToProps = (state) => ({
  badAuth: badAuthSelector(state),
  deviceToken: deviceTokenSelector(state),
  deviceID: deviceIDSelector(state),
  deviceKey: deviceKeySelector(state),
  uuid: uuidSelector(state),
});

const mapActionsToProps = {
  getDeviceToken,
  setUserSeeNewFeatureModal,
};

export default connect(mapStateToProps, mapActionsToProps)(Main);
