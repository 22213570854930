import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RequestLoginRoute = ({
  component: Component,
  senderToken,
  loginHasShown,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      senderToken || loginHasShown ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
              canSkip: true,
            },
          }}
        />
      )
    }
  />
);

RequestLoginRoute.propTypes = {
  component: PropTypes.func.isRequired,
  senderToken: PropTypes.string,
  location: PropTypes.object.isRequired,
  loginHasShown: PropTypes.bool.isRequired,
};

export default RequestLoginRoute;
