import React from 'react';
import PropType from 'prop-types';

import './Title.css';

const Title = ({ title }) => {
  return <div className="title">{title && <h2>{title}</h2>}</div>;
};

Title.propTypes = {
  title: PropType.string,
};

export default Title;
